.project-info-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    @media (max-width: 400px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .single-project-data {
        margin-bottom: 35px;

        @media (max-width: 400px) {
            margin-bottom: 20px;
        }

        h5 {
            margin-bottom: 5px;
        }

        p {

        }
    }
}

.project-details-card { 
    padding: 40px 45px 30px;
    background-image: radial-gradient(#E6E6E6 1px, transparent 0);
    background-size: 15px 15px;
    background-position: -15px -23px;
    position: relative;

    &::before {
        width: 0;
        height: 0;
        content: "";
        left: 45px;
        top: 0;
        border-top: 25px solid $second-color;
        border-right: 25px solid transparent;
    }

	.project-title { 
        padding-bottom: 20px;

		p { 
            color: #53545A;
		}
	}

	.project-date { 
        padding: 20px 0px;
        border-top: 1px solid #E4E7EF;

		p { 
            color: #53545A;
		}
	}

	.project-client {
        padding-bottom: 30px;

		p { 
            color: #53545A;
		}
	}

	.project-estimate {
        border-top: 1px solid #E4E7EF;
        padding-top: 30px;

		.single-data { 

			h6 { 
                line-height: 1;
                color: #53545A;
                margin-bottom: 5px;

			}

			p { 
                font-size: 14px;
			}

			.total-ton { 
                display: flex;
                margin-top: 10px;

				h3 { 
                    line-height: 1;
                    font-weight: 400;
                    margin-right: 5px;
				}

				span { 
                    color: $second-color;
                    font-weight: 700;
				}
			}
		}
	}
}

.core-bg {
    position: absolute;
    width: 41%;
    right: 0;
    height: 100%;
    content: "";
    z-index: -1;
    top: 0;
}

.portfolio-carousel-wrapper {
    overflow: hidden;
}

.portfolio-carousel-active {
    margin-left: 100px;
    overflow: hidden;

    @media (max-width: 1300px) {
		margin-left: 0;
    }
}

.single-project-item {
    margin-top: 50px;
    
    &:hover {
        .project-img {
    
            &::before {
                opacity: .6;
                visibility: visible;
            }

            .icon { 
                opacity: 1;
                visibility: visible;
            }
        }
    }

	.project-img {
        height: 615px;
        position: relative;
        display: block;

        @media (max-width: 1200px) {
			height: 500px;
        }

        @media (max-width: 991px) {
			height: 400px;
        }

        @media (max-width: 767px) {
			height: 350px;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease-in-out;
            background-color: $theme-color;
            width: 100%;
            height: 100%;
        }

		.icon { 
            color: #fff;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            transform: translate(-50%);
            opacity: 0;
            visibility: hidden;
            font-size: 30px;
            line-height: 1;
            transition: all .4s ease-in-out;
		}
	}

	.content-text { 
        margin-top: 20px;

		span { 
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            text-transform: uppercase;
		}

		h4 { 
            color: $theme-color;
            margin-bottom: 6px;
            font-size: 22px;

            @media (max-width: 767px) {
                font-size: 18px;
            }
		}

		p { 
            font-size: 12px;
            text-transform: uppercase;
            line-height: 1;
		}
	}
}

.project-carousel-wrapper {
    position: relative;
    z-index: -1;
    
    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        height: 130px;
        width: 100%;
        background-color: #fff;
        z-index: -1;
    }

    .project-carousel-card-active {
        position: relative;
        margin-bottom: -120px;
        
        @media (min-width: 1900px) {
            margin-left: 370px;
        }

        @media (max-width: 991px) {
            margin-bottom: -100px;
        }

        @media (max-width: 767px) {
            margin-bottom: -80px;
        }

        .owl-nav {
            div {
                position: absolute;
                left: 0;
                top: 50%;
                display: inline-block;
                padding: 10px 25px;
                background: $white;
                transform: translate(-50%);
                font-size: 20px;
                color: $second-color;
                transition: all .3s ease-in-out;

                @media (max-width: 1899px) {
                    transform: translateX(0);
                }

                &:hover {
                    background-color: $second-color;
                    color: $white;
                }
    
                &.owl-next {
                    right: 18%;
                    left: auto;

                    @media (max-width: 1899px) {
                        right: 0;
                    }
                }
            }
        }
    }

}

.single-project-card {
    position: relative;
    margin-top: 40px;

	.project-thumb { 
        background-color: #eee;
        height: 500px;
        display: block;

        @media (max-width: 1350px) {
            height: 450px;
        }

        @media (max-width: 991px) {
            height: 400px;
        }

        @media (max-width: 991px) {
            height: 300px;
        }
	}

	.contents {
        display: flex;
        background-color: $second-color;
        position: relative;
        align-items: center;
        padding: 20px 40px;

        @media (max-width: 1350px) {
            padding: 20px;
        }

		.project-number { 
            margin-right: 20px;

			h2 { 
                margin-bottom: 0;
                font-size: 60px;
			}
		}

		.project-details { 

			span { 
                font-size: 14px;
                font-weight: 700;
                line-height: 1;
			}

			h3 { 
                margin-bottom: 0;

				a { 

				}
			}

			p { 
                font-size: 14px;
                font-style: italic;
			}
		}
	}
}

.our-info-tabs-wrapper {
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $theme-color;
        width: 100%;
        height: 30%;
        content: "";
        z-index: -1;
    }
}

.project-tabs-wrapper {
    background: #fff;
    border: 1px solid #ebedf2;
    box-shadow: 0px 10px 50px rgba(3, 10, 30, 0.07);
    box-sizing: border-box;
    border-top: 0;

    .nav-pills {
        padding: 0;
        margin: 0;
        margin-left: -1px;

        .nav-item {
            width: 25%;

            @media (max-width: 991px) {
                width: 50%;
            }

            button {

                &.active {
                    background-color: $second-color;
                    color: #fff;
                }

                color: $theme-color;
                text-transform: uppercase;
                font-family: $heading-font;
                font-size: 20px;
                font-weight: 700;
                border-radius: 0;
                line-height: 80px;
                padding: 0px;
                text-align: center;
                border: 0;
                border-right: 1px solid #EBEDF2;
                border-bottom: 1px solid #EBEDF2;
                width: 100%;
                box-sizing: border-box;

                @media (max-width: 991px) {
                    font-size: 16px;
                    line-height: 70px;
                }

                i {
                    margin-right: 10px;
                }
            }
        }
    }

    .tab-content-wrapper {
        padding: 50px;

        @media (max-width: 991px) {
            padding: 30px;
        }

        h2 {
            @media (max-width: 991px) {
                font-size: 26px;
            }
        }

        a {
            font-size: 14px;
            line-height: 1;
            font-weight: 700;
            color: $second-color;
            text-transform: uppercase;
            display: block;
            margin-top: 20px;

            i {
                margin-left: 5px;
            }
        }

        .data-flex {
            margin-top: 30px;
            display: flex;

            .single-data {
                margin-right: 30px;
        
                p { 
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1;
                    font-family: $heading-font;
                    color: $theme-color;
                }
        
                span { 
                    font-size: 13px;
                    line-height: 1;
                }
        
                h6 {
                    color: $theme-color;
                    font-weight: 500;
                    font-size: 24px;

                    span { 
                        color: $second-color;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
            }
        }

        img {
            margin-left: 20px;

            @media (max-width: 991px) {
                margin-left: 0px;
                margin-top: 30px;
            }
        }
    }
}

.project-details-contents {
    border-bottom: 1px solid #EBEDF2;
}

.single-project-process {
    text-align: center;
    margin-top: 40px;

	.content { 

		h5 { 
            margin-bottom: 10px;
		}
	}
}

.project-item-card { 
    position: relative;
    overflow: hidden;
    margin-top: 30px;

	.project-thumb { 
        height: 350px;
        position: relative;
        display: block;

        @media (max-width: 991px) {
            height: 300px;
        }

        @media (max-width: 767px) {
            height: 280px;
        }

        @media (max-width: 480px) {
            height: 250px;
        }
	}

    .popup-link {
        cursor: crosshair;
        color: #fff;
    }

	.contents {
        background-color: #fff;
        position: absolute;
        padding: 30px;
        bottom: 0;
        transition: all .5s ease-in-out;
 left: 0 !important;
            opacity: 1 !important;
            visibility: visible !important;
            width: 80%;
		.project-link { 
            position: absolute;
            right: 20px;
            top: 0;
            transform: translateY(-50%);
            width: 54px;
            height: 54px;
            line-height: 54px;
            text-align: center;
            color: #fff;
            background-color: $second-color;

            &:hover {
                background-color: $theme-color;
            }
		}

		span { 
            color: $second-color;
            font-size: 14px;
            font-weight: 500;
		}

		h3 { 
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 20px;
            }
		}
	}


    
}

.project-item-box { 
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    display: block;
    z-index: 1;

    .project-thumb { 
        height: 500px;
        display: block;
        background-position: center;
        cursor:crosshair;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 80%;
            background-color: $theme-color;
            opacity: .6;
            content: "";
            visibility: hidden;
            transition: all .4s ease-in-out;
        }

        @media (max-width: 1191px) {
            height: 400px;
        }

        @media (max-width: 991px) {
            height: 320px;
        }

        @media (max-width: 767px) {
            height: 290px;
        }

        @media (max-width: 480px) {
            height: 260px;
        }
    }

    .content-wrap {
        position: absolute;
        bottom: -40%;
        left: 0;
        transition: all .5s ease-in-out;
        z-index: 2;
    }

    .contents { 
        width: 100%;
        height: 100%;
        padding: 30px;

		span { 
            color: $second-color;
            font-size: 14px;
            font-weight: 500;
		}

		h3 { 
            margin-bottom: 0;
            color: #fff;

            @media (max-width: 767px) {
                font-size: 20px;
            }
		}

        p {
            color: #fff;
        }
    }

    &:hover {

        .project-thumb {  
            &::before {
                visibility: visible;
                height: 100%;
            }
        }

        .content-wrap { 
            bottom: 0;
            opacity: 1;
            visibility: visible;
        }
        
        .project-link {
            opacity: 1;
            visibility: visible;
            top: 20px;
        }

    }

    .project-link { 
        position: absolute;
        right: 20px;
        top: -60px;
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        color: #fff;
        background-color: $second-color;
        opacity: 0;
        visibility: hidden;
        transition: all .6s ease-in-out;

        &:hover {
            background-color: $theme-color;
        }
    }
}

.portfolio-cat-filter {
    

    button {
        border: none;
        background-color: #fff;
        display: inline-block;
        padding: 18px 38px;
        line-height: 1;
        transition: all .3s ease-in-out;            
        margin: 0px 5px;
        font-weight: 700;
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
        font-family: $heading-font;
        text-transform: uppercase;
        font-size: 14px;

        @media (max-width: 767px) {
            padding: 15px 25px;
            margin-bottom: 10px;          
        }

        &.active, &:hover {
            color: #fff;
            background-color: $theme-color;
        }
    }

}

.portfolio-showcase-carousel-active {

    .single-recent-portfolio { 
        width: 100%;
        height: 460px;
        background-color: #eee;
        display: flex;
        align-items: flex-end;
        margin-top: 30px;

        @media (max-width: 767px) {
            height: 350px;
        }

        @media (max-width: 500px) {
            height: 300px;
        }

        &:hover {

            .project-details { 
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }

        .project-details { 
            background-color: #fff;
            display: inline-block;
            padding: 25px 20px;
            position: relative;
            padding-right: 90px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(25px);
            transition: all .3s ease-in-out;
            
            h5 { 
                font-weight: 600;
                margin-bottom: 0;
                line-height: 1.4;
                text-transform: uppercase;

                &:hover {
                    a {
                        color: $theme-color-5;
                    }
                }
            }
    
            span { 
                font-size: 14px;
                text-transform: uppercase;
                color: #53545a;
            }
    
            .project-link {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 45px;
                height: 45px;
                border: 1px solid #EEEEEE;
                color: #9F9F9F;
                line-height: 40px;
                text-align: center;
                font-size: 16px;
                box-sizing: border-box;
                transition: all .3s ease-in-out;

                &:hover {
                    color: $theme-color-5;
                    border-color: $theme-color-5;
                }
            }
        }
    }

    &.owl-theme {
        .owl-dots {
            margin-top: 60px !important;
            margin-bottom: -10px;

            .owl-dot {

                &.active, &:hover {
                    span  { 
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                span  {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    background: transparent;
                    margin: 0px 7px;


                    &::before {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        content: "";
                        background: #161616;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s ease-in-out;
                    }
                }
            }
        }
    }
}

.work_process__wrapper{
    background: #F8F8F8;
    .process__widget{
        padding: 0px 15px;
        .icon__widget{
            margin: 0 auto;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px dashed #FF5E14;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }
        .icons{
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            box-shadow: 0px 0px 10px rgba(255, 94, 20, 0.15);
        }
        h4{
            font-family: $text-font;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 10px;
        }
        p{
            font-family: $heading-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
        
    }
    .arrow__element{
        position: absolute;
        top: 15%;
        right: -15%;
    }
}
.project__item{
    .project__img{
        width: auto;
        height: 560px;
        position: relative;
        .project__content{
            @include transition(0.4s);
            visibility: hidden;
            opacity: 0;
            overflow: hidden;
            margin: 20px;
            bottom: 0;
            position: absolute;
            background-color: $second-color;
            border-radius: 4px;
            padding: 25px;
            p{
                font-family: $heading-font;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-transform: uppercase;
                color: $white;
                margin-bottom: 5px;
            }
            h3{
                font-family: $text-font;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: $white;
                margin-bottom: 10px;
            }
            a{
                font-family: $heading-font;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-transform: uppercase;
                color: $title-color-2;
                i{
                    margin-left: 10px;
                }
                transition: all ease-in-out 0.4s;
                &:hover{
                    color: $white;
                }
            }
            .element{
                position: absolute;
                bottom: -40px;
                left: -70px;
            }
        }
        &:hover{
            .project__content{
                visibility: visible;
                opacity: 1;
            }
        }
    }
}