.single-fun-fact {
   h2 {
      font-size: 50px;
      margin-bottom: 0;
   }

   h3 {
      margin-bottom: 0;
   }
}

.border-line {
   position: relative;

   .single-fun-fact {
      h2 {
         color: $second-color;

         span {
            color: $second-color;
         }
      }

      h3, h4 {
         margin-bottom: 0;
      }
   }
}

.single-fun-fact {
   &.style-2 {
      margin-bottom: 20px;
      z-index: 2;
      position: relative;

      h2 {
         color: $second-color;

         span {
            color: $second-color;
         }
      }

      h3, h4 {
         margin-bottom: 0px;
         color: $white;
      }
   }
}

.skill-wrapper {
   position: relative;
}

.koppa-img {
   img {
      position: absolute;
      left: 0;
      bottom: -20px;
   }
}

.faq-funfact-section {
   position: relative;
   z-index: 1;

   &::before {
      position: absolute;
      left: 0;
      top: 0;
      // background-image: url("../img/map.png");
      background-repeat: no-repeat;
      background-color: $theme-color;
      width: 100%;
      height: 60%;
      content: "";
      z-index: -1;
   }
}

.single-progress-bar {
   font-family: $heading-font;
   font-size: 16px;
   position: relative;
   overflow: hidden;
   margin-top: 30px;

   h5 {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 600;
   }
   span {
      font-family: $heading-font;
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
   }

   .progress {
      margin-top: 15px;
      height: 10px;
      background-color: #e4e9f9;

      .progress-bar {
         background-color: $second-color;
         border-radius: 6px;
      }
   }
}


.fun-fact-wrapper.pt-70.pb-100 {
    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 80px;
    }
}

.fun-fact-banner {
    padding-top: 120px;
    padding-bottom: 290px;

    @media (max-width: 1199px) {
        padding-top: 100px;
        padding-bottom: 270px;
    }

    @media (max-width: 767px) {
        padding-top: 80px;
        padding-bottom: 250px;
    }
}

.single-skill-circle-bar  {
    h6 {
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
    }
}