.block-contents { 

	.section-title {
        position: relative;

        h5 {
            font-size: 160px;
            line-height: 1;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $theme-color;
            -webkit-text-fill-color: transparent;
            opacity: .10;
            margin-bottom: 0;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            top: -53px;
            font-family: $body-font;
            font-weight: 700;

            &.textleft {
                left: 0px !important;
                transform: translateX(-5px);
            }

            &.text-white {
                -webkit-text-stroke-color: $white;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

		span { 
            font-weight: 700;
            text-transform: uppercase;
            color: $second-color;
		}

		h2 { 
            font-size: 57px;
            line-height: 1.17;
        
            @media (max-width: 1191px) {
                font-size: 46px;
            }

            @media (max-width: 991px) {
                font-size: 42px;
            }

            @media (max-width: 767px) {
                font-size: 36px;
            }

            @media (max-width: 500px) {
                font-size: 32px;
            }
		}
	}

	p { 
        margin-top: 15px;
	}

    blockquote {
        border-left: 5px solid $second-color;
        font-style: italic;
        padding-left: 20px;
        color: $theme-color;
        font-size: 20px;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
.section-title_2{
    h5{
        font-family: $body-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $secondary;
        margin-bottom: 20px;
    }
    h2 { 
        font-family: $text-font;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        color: $title-color-2;
        @media (max-width: 767px) {
            font-size: 32px;
        }

        @media (max-width: 500px) {
            font-size: 30px;
        }
    }
}
.section-rotate-title {
    font-size: 160px;
    line-height: 1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $theme-color;
    -webkit-text-fill-color: transparent;
    opacity: .10;
    top: 42%;
    position: absolute;
    left: -100px;
    transform: rotate(270deg);
    font-family: $body-font;

    @media (max-width: 991px) {
        display: none;
    }
}

.section-title-3 { 

	h6 { 
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: $theme-color-4;
        margin-bottom: 10px;
        margin-top: -6px;
	}

	h2 { 
        color: #161616;
        font-size: 60px;
        line-height: 100%;
        margin-bottom: 20px;
        font-weight: 700;
        text-transform: capitalize;

        @media (max-width: 1200px) {
            font-size: 42px;
            line-height: 115%;
        }

        @media (max-width: 991px) {
            font-size: 38px;
        }

        @media (max-width: 767px) {
            font-size: 34px;
        }

	}

	p { 
        font-size: 16px;
	}
}


// Section Padding - Change with your need.
.section-padding {
    padding: 120px 0px;

    @media (max-width: 1191px) {
        padding: 100px 0px;
    }

    @media (max-width: 767px) {
        padding: 80px 0px;
    }
    &.section-padding_3{
        @media (max-width: 767px) {
            padding: 50px 0px;
        }
    }
}

.section-bg {
    background-color: $section-bg;
}

.theme-bg {
    background-color: $theme-color;
}

.bg-gray {
    background-color: #F9F9F9;
}

.cta-theme-bg {
    background-color: $second-color;
}

.page-banner-wrap {
    background-color: $theme-color;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    &::before {
        position: absolute;
        left: 0;
        content: "";
        top: 0;
        background: #000;
        width: 100%;
        height: 100%;
        opacity: .10;
        z-index: -1;
    }

    .page-heading {
        h1 {
            font-size: 80px;
            line-height: 110%;

            @media (max-width: 991px) {
                font-size: 60px;
            }

            @media (max-width: 767px) {
                font-size: 40px;
            }
        }
    }
  
}

.section-title-4 {
    h2 {
        font-size: 50px;
        font-weight: 600;
        line-height: 115%;
        color: $text-color-black;
        margin-top: -8px;
        margin-bottom: 20px;

        @media (max-width: 991px) {
            font-size: 40px;
        }

        @media (max-width: 767px) {
            font-size: 32px;
        }

    }

    p {
        font-size: 16px;
        line-height: 26px;
    }
}

.bg-black {
    background-color: $text-color-black !important;
}
.container{
    max-width: 1230px !important;
}