* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body,
html {
    height: 100%;
}
html {
    scroll-behavior: smooth;
}

html, body, div, span, img, 
ol, ul, li, a, p, h1, h2, h3, 
h4, h5, h6, option, legend, label, 
table, th, td, tr, article, aside, 
caption, figure, footer, header, hgroup, 
mark, nav, section, time, audio, video {
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0;
}
img {
    max-width: 100%;
}
a {
  &:hover, &:focus, &:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
  }
}
input:focus {
  outline: none;
}
input::-moz-focus-inner {
    border: 0;
    display: none;
}
input::-webkit-focus-inner {
    border: 0;
    display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
 -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

::placeholder {
    color: #555;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #555;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color: #555;
}

table {
	border-collapse: collapse;
}

html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

ul, li {
	margin: 0;
	padding: 0;
}

figure {
    margin: 0px;
}

form {
    position: relative;
}

.form-control:focus {
    outline: none !important;
    border: none !important;
    box-shadow:  0 0 0;
}

h1 a ,h2 a, h3 a, h4 a, h5 a, h6 a  {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
}

h1:hover a, h2:hover a, h3:hover a, h4:hover a, h5:hover a, h6:hover a  {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

hr {
    margin: 0;
    padding: 0;
    border-color: #000;
}

ul {
    list-style-type: none;
}

li {
    list-style: none;
    position: relative;
}

a,
button {
    position: relative;
    
    &:hover,
    &:focus {
        outline: 0;
        text-decoration: none;
    }
}

input, textarea, button {
    border: 0px;
}
.px-24{
    padding: 0 24px;
}
.container {
    max-width: 1200px;
    width: 100%;
}
.cookie_cont {
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 99999;
}
.cookie_link{
    color: #79ba35;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1.5;
}
.category_img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}