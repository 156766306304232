.single-testimonial-item { 
    background-color: #F9F9F9;
    position: relative;
    padding: 95px;
    padding-top: 130px;
    padding-bottom: 0;
    text-align: center;
    margin-top: 70px;

    @media (max-width: 991px) {
        padding: 75px;
        padding-top: 100px;
    }

    @media (max-width: 767px) {
        padding: 50px;
        padding-top: 80px;
    }

    @media (max-width: 500px) {
        padding: 25px;
        padding-top: 70px;
    }

    &::before {
        width: 87px;
        height: 87px;
        background: $second-color;
        position: absolute;
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        content: "\f10e";
        font-family: $fa;
        font-weight: 700;
        color: #fff;
        line-height: 87px;
        text-align: center;
        font-size: 32px;
    }

	p { 
        font-size: 22px;
        font-style: italic;
        line-height: 150%;

        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 1.9;
        }

        @media (max-width: 500px) {
            font-size: 17px;
        }
	}

	.client-info { 
        margin-top: 50px;

        @media (max-width: 767px) {
            margin-top: 20px;
        }

		h5 { 
            line-height: 1;
            margin-bottom: 10px;
		}

		span {
            line-height: 1;
		}
	}

	.client-img { 
        width: 86px;
        height: 86px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: #eee;
        margin-bottom: -43px;
        position: relative;
        bottom: -43px;
	}
}

.testimonial-carousel-active {
    .owl-item {
        padding-bottom: 90px;

        @media (max-width: 991px) {
            padding-bottom: 15px;
        }

        @media (max-width: 767px) {
            padding-bottom: 36px;
        }

        @media (max-width: 500px) {
            padding-bottom: 60px;
        }
    
    }

    .owl-nav {
        div {
            position: absolute;
            left: 0;
            top: 50%;
            display: inline-block;
            padding: 30px 15px;
            background: $second-color;
            transform: translate(-50%);
            font-size: 20px;
            color: #fff;

            @media (max-width: 1200px) {
                transform: translateY(0);
            }

            @media (max-width: 500px) {
                display: none;
            }

            &.owl-next {
                right: 0;
                transform: translateX(50%);
                left: auto;

                @media (max-width: 1200px) {
                    transform: translateX(0);
                }
            }
        }
    }
}

.single-testimonial-carousel { 
    position: relative;
    z-index: 1;

	.icon { 
        margin-bottom: 20px;
	}

	p { 
        font-size: 28px;
        font-style: italic;
        line-height: 135%;
	}

	span { 
        position: relative;
        padding-left: 80px;
        display: block;
        margin-top: 10px;

        &::before {
            position: absolute;
            left: 0;
            content: "";
            top: 50%;
            transform: translateY(-50%);
            background-color: #707070;
            width: 60px;
            height: 1px;
        }

		b { 
            position: relative;
            padding-right: 5px;

            &::before {
                position: absolute;
                right: -1px;
                content: ",";
            }
		}
	}
}

.testimonial-img-right {
    position: relative;

    &::before {
        position: absolute;
        right: 0;
        width: 465px;
        height: 465px;
        background: #f8f8f8;
        z-index: -1;
        content: "";
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.testimonial-carousel-list {

    &.style-2 {

        .owl-nav {
            
            div {
                background-color: $theme-color-5;
                border-color: $theme-color-5;
                color: $text-color-black;

                &:hover {
                    background-color: $text-color-black;
                    border-color: $text-color-black;
                    color: #fff;
                }
            }

        }
    }

    .owl-nav {
        margin-top: 30px;
        
        div {
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 50%;
            background-color: $theme-color-4;
            color: #fff;
            text-align: center;
            display: inline-block;
            margin-top: 10px;
            transition: all .3s ease-in-out;
            border: 1px solid #eee;
            box-sizing: border-box;

            &:hover {
                background-color: $theme-color;
            }
            
            &:first-child {
                margin-right: 10px;
            }
        }
    }

}

.testimonial__single__element{
    .testimonial__man{
        position: relative;
        height: 322px;
        width: auto;
        span{
            position: absolute;
            bottom: 20px;
            right: 30px;
        }
    }
    .content{
        @media( max-width:768px) {
           margin-top: 50px;
        }
        i{
            font-size: 50px;
            color: $second-color;
            margin-bottom: 40px;
        }
        p{
            font-family: $text-font;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        span{
            font-family: $body-font;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #020231;
        }
    }
}
.our__testimonial__img{
    max-width: 100%;
    position: absolute;
    bottom: 0px;
    left: 39%;
    width: 200px;
    @media( max-width:768px) {
        bottom: 20%;
        left: 45%;
    }
    @media( max-width:500px) {
        bottom: 30%;
        left: 45%;
    }
    @media( max-width:400px) {
        bottom: 30%;
        left: 35%;
    }
    .single__img{
        width: 60px;
        margin: 5px;
    }
}
.our_testimonials__wrapper{
    padding-top: 120px;
    @media( max-width:900px) {
        padding-top: 100px;
    }
    @media( max-width:768px) {
        padding-top: 80px;
    }
    @media( max-width:576px) {
        padding-top: 50px;
    }
}