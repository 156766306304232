/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
 @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap');

 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600;700&display=swap');

 @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Font Family Variable with Value
// font-family: 'Exo 2', sans-serif;
// font-family: 'Roboto', sans-serif;
// font-family: 'Oswald', sans-serif;
// font-family: 'Cambria', sans-serif;

$heading-font: 'Exo 2', sans-serif;
$body-font: 'Roboto', sans-serif;
$text-font:'Poppins', sans-serif;

//font-family: "Font Awesome 5 Pro";
$fa: "Font Awesome 5 Pro";

body {
    font-family: $body-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #53545A;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: 700;    
    color: #001659;
    text-transform: uppercase;
}

h1 {
    font-size: 60px;
    line-height: 1.17;

    @media (max-width: 991px) {
        font-size: 42px;
    }

    &.fs-lg {
        font-size: 90px;
        text-transform: uppercase;

        @media (max-width: 1200px) {
            font-size: 70px;
        }

        @media (max-width: 991px) {
            font-size: 50px;
        }

        @media (max-width: 500px) {
            font-size: 46px;
        }
    }
}

h2 {
    font-size: 30px;
    line-height: 145%;
}

h3 {
    font-size: 24px;
    line-height: 145%;
}

h4 {
    font-size: 22px;
    line-height: 145%;
}

h5 {
    font-size: 20px;
    line-height: 145%;
}

h6 {
    font-size: 18px;
    line-height: 145%;
}

a {
    text-decoration: none;
    font-weight: 600;
    outline: none !important;
    cursor: pointer;
    font-size: 16px;
    line-height: 28px;
    @include transition(.3s);
    color: $heading-color;
}

p {
    margin: 0px;
}

.font-oswald {
    h1, h2, h3, h4, h5, h6, a {
        font-family: 'Oswald', sans-serif;
    }
}

.font-cambria {
    h1, h2, h3, h4, h5, h6, a {
        font-family: 'Cambria', sans-serif;
    }
}