/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd {
    line-height: 1;
    font-weight: 700;
    display: inline-block;
    @include transition(.4s);
    text-transform: uppercase;
    font-size: 14px;   
}

.btn {
    border: 0;
    background: transparent;
    border-radius: 0;
    line-height: 1;
    padding: 0;
    margin: 0;
    font-size: inherit;
}

.theme-btn {
    color: $white;
    @extend .btnd;    
    padding: 25px 43px 21px;
    background-color: $second-color;
    transition: all .3s ease-in-out;

    @media (max-width: 991px) {
        padding: 16px 30px 14px;
    }
    &.theme_btn__2{
        @include btn-trns;
    }
    &.theme_btn__3{
        @include btn-trns;
        border: 1px solid#020231;
        color: #020231;
        text-transform: capitalize;
    }
    &.theme-2 {
        background: $theme-color-2;
        padding: 22px 40px;

        &:hover {
            background: $second-color;
        }

        &.white {
            background: $white;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
            color: $second-color;

            &:hover {
                background: $second-color;
                color: $white;
            }
        }
    }

    &.style-3 {
        background-color: $theme-color-4;
        color: #fff;
        border-radius: 4px;
        padding: 20px 41px 18px;
        font-size: 14px;
        line-height: 1 !important;

        &.white {
            background: #fff;
            border-color: #fff;
            color: #161616;
        }

        &:hover {
            background: $theme-color;
            color: #fff;
        }
    }

    &.style-4 {
        background-color: $theme-color-5;
        color: #161616;
        border-radius: 5px;
        padding: 21px 38px 20px;
        font-size: 14px;
        line-height: 1 !important;
        font-weight: 500;
        text-transform: uppercase;

        i {
            margin-left: 0px;
            font-size: 16px;
        }

        &.white {
            background: #fff;
            border-color: #fff;
            color: #161616;
        }

        &:hover {
            background: $theme-color;
            color: #fff;
        }
    }

    
    @media (max-width: 767px) {
        padding: 14px 28px 12px;
    }

    @media (max-width: 580px) {
        padding: 18px 35px 15px;
    }

    @media (max-width: 480px) {
        padding: 16px 30px 14px;
        font-size: 15px;
    }

    i {
        margin-left: 10px;
    }

    &.no-fil {
        background: transparent;
        margin-left: 15px;
        color: #252525;
        box-sizing: border-box;
        border: 2px solid #e9e9e9;

        @media (max-width: 500px) {
            margin-left: 0;
        }

        &:hover {
            background: $theme-color;
            border-color: $theme-color;
        }
    }

    &.black {
        background: #191c28;
        color: $white;

        &:hover {
            background: $theme-color;
            color: #fff;            
        }
    }

    &.minimal-btn {
        color: $theme-color;
        background: transparent;
        box-shadow: inherit;
        border: 1px solid $theme-color;

        &:hover {
            background: $theme-color;
            box-shadow: inherit;
            color: $white;
        }
    }

    &:hover {
        background: $theme-color;
        color: $white;
    }

}

.video-btn {
    @extend .btnd;

    i {
        margin-right: 10px;
    }
}

.video-play-btn {    

    .play-video {        
        border-radius: 50%;
        width: 90px;
        height: 90px;
        background-color: $white;
        color: $second-color;
        line-height: 90px;
        transition: .3s;
        text-align: center;
        animation: playVideo infinite 3.5s;
        display: inline-block;
        margin: 0px auto;
        font-size: 20px;

        @media (max-width: 767px) {
            width: 60px;  
            height: 60px;
            line-height: 60px;
            font-size: 14px;
        }
    
        &:hover {
            color: $theme-color;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        }
    }

    .play-text {
        font-weight: 700;
    }
}

.plus-text-btn {
    display: inline-block;
    overflow: hidden;
    clear: both;
    margin-top: 20px;

    &:hover {
        .icon {
            background: $theme-color;
            color: #fff;
        }
        color: $theme-color;
    }
    
    .icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        background: $second-color;
        border-radius: 50px;
        margin-right: 20px;
        float: left;
        overflow: auto;
        transition: all .3s ease-in-out;
    }

    .link-text {
        float: left;
        overflow: auto;
        padding-top: 3px;
    }

    span {
        display: block;
    }

}
//5
.btn-wepper{

    .bg-bth, .theme-btns{ 
        overflow: hidden;
        @include transition(0.3s);
        border-radius: 5px;
        padding: 20px 40px;
        color: #001659;
        font-family: $body-font;
        font-weight: 500;
        background-color: transparent;
        &:hover{
            color: $white;
            background-color: $second-color;
        }
    }
    .bg-bth{
        border: 2px solid #C2C5D7;
        padding: 18px 40px;
        &:hover{
            border: 2px solid $second-color;
            color: $white;
            background-color: $second-color;
        }
    }
    .theme-btns{
        padding: 20px 40px;
        border: 0px solid #C2C5D7;
        color: $white;
        background-color: $second-color;
        &:hover{
            background-color:#001659;
        }
    }
    .btns-plan{
        overflow: hidden;
        padding: 15px 40px;
    }
    a{
        font-size: 16px !important;
        font-family: $body-font !important; 
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 34px !important;
        i{
            margin-right: 15px;
            font-size: 12px;
            padding: 10px 11px;
            background-color:  $blue;
            color: $white;
            border-radius: 100%;
        }
        &:hover{
            i{
               color:$second-color;
            }
        }
    }
}