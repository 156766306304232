/* ----------------------------------
	Call To Action Section Styles
 ------------------------------------ */
 .cta-wrapper {
	 padding: 50px 0px 35px;

	 &.style-2 {
		 margin-left: 0;
	 }

	.cta-text { 

		i {
			font-size: 100px;
			line-height: 1;
			color: #fff;
			margin-right: 25px;

			@media (max-width: 991px) {
				font-size: 80px;
			}

			@media (max-width: 767px) {
				font-size: 60px;
			}
		}

		h2 {
			font-size: 30px;
			color: #fff;

			@media (max-width: 991px) {
				font-size: 26px;
			}
		}
	}

	.theme-btn { 
		border: 1px solid #fff;
		box-sizing: border-box;

		&:hover {
			border-color: $theme-color;
		}
	}
}

.pricing-cta-wrapper {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		content: "";
		height: 100%;
		background: $theme-color;
		opacity: .6;
		z-index: -1;
	}

	.pricing-contents { 
		
		h1 { 
			display: block;
			margin-top: -15px;
		}
	
		a { 
			line-height: 1px;
			padding: 28px 45px;
			display: inline-block;
			border: 2px solid #fff;
			text-transform: uppercase;
			overflow: hidden;
			margin-right: 30px;
			margin-top: 25px;

			&:hover {
				color: $white;
				background: $second-color;
				border-color: $second-color;
			}
		}
	
		.btn-cta {
			display: inline-block;
			overflow: hidden;
			clear: both;
			margin-bottom: -15px;
			margin-top: 25px;
	
			.icon { 
				float: left;
				overflow: hidden;
				margin-right: 20px;
				font-size: 60px;
				color: $second-color;
			}
	
			.content { 
				overflow: hidden;
				text-align: left;
	
				span { 
	
				}
	
				h3 { 
	
				}
			}
		}
	}

}

.cta-promo-wrapper {
    padding: 85px 0px;

    .cta-heading {
        h2 {
            font-size: 50px;
            line-height: 1;
            text-transform: capitalize;

            @media (max-width: 991px) {
                font-size: 42px;
                line-height: 1.3;
            }

            @media (max-width: 767px) {
                font-size: 36px;
            }
        }
    }
}

.video-popup-wrapper {
    height: 557px;

	@media (max-width: 500px) {
		height: 420px;
	}

    &.skill-video {
        height: 490px;

		@media (max-width: 500px) {
			height: 400px;
		}
    }
}

.promo-banner-cta {
    h2 {
        font-size: 50px;
        font-weight: 600;

        @media (max-width: 1191px) {
			font-size: 46px;
		}

        @media (max-width: 991px) {
			font-size: 42px;
		}

        @media (max-width: 767px) {
			font-size: 38px;
		}

        @media (max-width: 500px) {
			font-size: 32px;
		}
    }

    a {
        margin-top: 30px;
    }
}

.call-phone-cta { 
    background-color: $theme-color-5;
    padding: 67px 50px;
    border-radius: 10px;

    @media (max-width: 991px) {
        padding: 30px;
    }


	.icon { 
        float: left;
        margin-right: 20px;
        font-size: 70px;
        overflow: hidden;
        line-height: 1;
        color: $text-color-black;

        @media (max-width: 767px) {
            font-size: 60px;
        }
	}

	.content { 
        overflow: hidden;

		span { 
            text-transform: uppercase;
            font-size: 16px;
            display: block;
            padding-bottom: 10px;

		}

		h3 { 
            font-size: 28px;
            line-height: 1;
            margin-bottom: 0;
            color: $text-color-black;

            @media (max-width: 767px) {
               font-size: 22px;
            }
		}
	}
}
.cat__wrapper{
	@include overlay(#01011c99,0.6);
	h2{
		margin: 0 auto;
		width: 994px;
		max-width: 100%;
		font-family: $text-font;
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 54px;
		text-align: center;
		color: $white;
		text-transform: capitalize;
		@media (max-width:569px) {
			font-size: 28px;
		}
	}
	.play-video{
		background: rgba(255, 255, 255, 0.3);
		color: $white;
	}

}