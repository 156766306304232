/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */
$white:#FFF;
$gray:#f4f7fd;
$red: #FF8888;
$base: #1085A9;
$black:#000;
$blue: #01A0E1;
$pink: #FF681B;
$info: #FAA21A;
$green: #3FAD48;
$primary: #8DC63F;
$secondary: #F47102;

// Theme Color - Defualt Colors
$textcolor: #53545A;
$section-bg: #f6f6f6;
$theme-color: #001659;
$theme-dark: #001041;
$second-color: #FF5E14;
$second-color-dark: #cc4100;
$theme-color-2: #ff5e14;
$theme-color-3: #ffd039;
$theme-color-4: #4E5AFF;
$theme-color-5: #FFBB00;
$text-color-black: #161616;
//
$div-color:#F3F2F9;
$hover-color:#ccd0dec2;
$heading-color: $theme-color;
$title-color:#FFAE89;
$title-color-2:#020231;


$box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
$sticky-bg-1: $theme-color;
$sticky-bg-2: $theme-color-2;


@mixin border-radius($r) {
    -webkit-border-radius: $r;
    -moz-border-radius: $r;
    -ms-border-radius: $r;
    border-radius: $r;
}

@mixin transition($transition) {
    -webkit-transition: all $transition ease-in-out;
    -moz-transition: all $transition ease-in-out;
    -ms-transition: all $transition ease-in-out;
    -o-transition: all $transition ease-in-out;
    transition: all $transition ease-in-out;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin coverbg {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

@mixin appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        cursor: pointer;
        display: block;
        width: 8px;
        color: #333;
        text-align: center;
        position: relative;
    }
}

@mixin overlay($color, $opacity) {
    z-index: 1;
    position: relative;

    &:before {
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background: rgba($color, $opacity);
        @include transition(all 0.3s ease);
    }
}

@mixin absolute($z-index) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: $z-index;
}

@mixin before($zindex) {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: $zindex;
}

@function calculateRem($size) {
    $rem-size: $size / 16px;
    @return $rem-size * 1rem;
}

@mixin font-size($size: 16px) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin letter-spacing($letter-track, $font-size) {
    letter-spacing: $letter-track * $font-size / 1000 + px;
}

@mixin align-center($type: 'all') {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    // all
    @if $type=='all' {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        // horizontal
    }

    @else if $type=='horizontal' {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        // vertical
    }

    @else if $type=='vertical' {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        // vertical
    }

    @else if $type=='bottom' {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

@mixin placeholder($color, $font_size, $font_weight) {
    &::-webkit-input-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &::-moz-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &:-ms-input-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &:-moz-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }
}

@mixin angle($pseudo, $flip: false, $use-background-color: false, $angle: 3deg) {

    // $pseudo: before, after, both
    @if $pseudo=='before'or $pseudo=='after'or $pseudo=='both' {
        z-index: 1;
        position: relative;
        $selector: if($pseudo=='both', '&:before,&:after', '&:#{$pseudo}');

        #{$selector} {
            left: 0;
            right: 0;
            content: '';
            z-index: -1;
            height: 100px;
            display: block;
            position: absolute;
            -webkit-backface-visibility: hidden;

            @if $use-background-color {
                background: $color__background-body;
            }

            @else {
                background: inherit;
            }
        }

        @if $pseudo=='before' {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                }

                @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo=='after' {
            #{$selector} {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                }

                @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo=='both' {

            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                }

                @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 0;
                }

                @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }
    }
}



//5
@mixin sectionhead-h1 {
    text-transform: capitalize;
    font-size: 36px;
    margin-bottom:5px;
    padding-bottom: 0px;
    font-family: $body-font;
    span{
        color: $second-color;
    }
    @media (max-width:550px) {
        font-size: 27px !important;
    }
}
@mixin sectionhead-h5 {
    margin-bottom:0px;
    padding-bottom: 0px;
    color:$theme-color;
    font-weight: 400;
    font-family:$body-font;
    font-size: 15px;
}
@mixin icon($hight,$width,$bgcolor) {
        @include transition(0.4s);
        width: $width;
        height:$hight;
        text-align: center;
        line-height: 47px;
        background-color:$bgcolor;
        display: inline-block;
        margin-left: 10px;
        border-radius: 100%;
}

@mixin hover-lay($color) {
    z-index: 60;
    @include transition(0.4s);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: block;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    content: "";
    clear: both;
    background:$color ;
    @include border-radius(10px);
}

@mixin after {
    &::after{
        cursor: pointer;
        opacity: 1;
        visibility: visible;
    }
}

@mixin hover {
    &:hover{
        @include after;
        .project-name{
            h3{
                color: #fff;
            }
            p{
                color: #fff;
            }
        }
        .bg-bth{
            background-color: $second-color;
            color: #fff !important;
        }
    }
}

@mixin btn-trns {
    border: 1px solid #FFFFFF;
    padding: 14px 28px;
    border-radius: 4px;
    background-color: transparent ;
    @include transition (0.4s);
    &:hover{
        border: 1px solid $second-color ;
        background-color: $second-color !important;
    }
}