/* ----------------------------------
  Service Section Styles
 ------------------------------------ */
 .single-service-box { 
    padding: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all .4s ease-in-out;

    &:hover {
        &::after {
            opacity: 0;
            visibility: hidden;
        }

        .service-bg { 
            opacity: 1;
            visibility: visible;
            transform: scale(1.1);
        }

        .contents { 

            h4, p { 
                color: #fff;
            }
        }
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #fff;
        opacity: 1;
        visibility: visible;
        transition: all .4s ease-in-out;
        left: 0;
        top: 0;
        z-index: -1;
    }

	.icon { 
        color: $second-color;
        font-size: 54px;
        line-height: 1;
	}

	.contents { 

		h4 { 
            margin: 20px 0px;
		}

		p { 

		}
	}

    .service-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        content: "";
        top: 0;
        left: 0;
        background-position: center center;
        transition: all .4s ease-in-out;
        transform: scale(.9);

        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            background-color: $theme-color;
            opacity: .6;
            left: 0;
            top: 0;
        }
    }
}

.single-service-card { 
    border: 1px solid #EBEDF2;
    background-color: #fff;
    margin-top: 40px;
    &.single-service-card_2{
        border: 0px solid #EBEDF2;
        padding: 0px 10px;
        z-index: 1;
        position: relative;
        background-color: transparent !important;
        .service-cover-img { 
            height: 200px;
            border-radius: 4px;
        }
        .content { 
            padding: 30px 10px 50px 10px !important;
            h4{
                font-family: $text-font;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                a{
                    position: relative;
                    padding: 0px 0px;
                    visibility: visible;
                    opacity: 1;
                }
            }
            a{
                @include transition(0.4s);
                padding: 10px 20px;
                border-radius: 4px;
                text-transform: capitalize;
                margin-bottom: -15px;
                position: absolute;
                bottom: -1%;
                visibility: hidden;
                opacity: 0;
            }
        }
        &::after{
            position: absolute;
            content: '';
            clear: both;
            display: block;
            left: 0;
            bottom: 0;
            height: 75%;
            width: 100%;
            background-color: $white;
            border-radius: 4px;
            z-index: -1;
            box-shadow: 0px 6px 20px rgba(1, 1, 28, 0.2);
        }
        &:hover{
            a{
                visibility: visible;
                opacity: 1;
            }
        }
    }
	.service-cover-img { 
        height: 230px;
	}

	.content { 
        padding: 30px 40px;

		.icon-title {
            display: flex;
            align-items: center;

			.icon { 
                font-size: 55px;
                color: $second-color;
                line-height: 1;
                margin-right: 20px;
			}

			.service-title { 

				h4 { 
                    &:hover {
                        color: $second-color;
                    }
				}
			}
		}

        p { 

        }

        .read-more {
            color: $second-color;
            text-decoration: underline;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            display: block;
            margin-top: 15px;

            &:hover {
                color: $theme-color;
            }

            i {
                margin-left: 5px;
            }
        }
	}
}

.single-best-featured {
    margin-top: 50px;

    &.right {
        @media (min-width: 768px) {
            text-align: right;

            .icon {
                float: right;
                overflow: hidden;
                font-size: 60px;
                color: $second-color;
                margin-left: 20px;
                margin-right: 0;
            }
        }
    }

	.icon {
        float: left;
        overflow: hidden;
        font-size: 60px;
        color: $second-color;
        margin-right: 20px;
	}

	.content-text { 
        overflow: hidden;

		h5 { 
            margin-top: -4px;
            margin-bottom: 10px;
		}

		p { 

		}
	}
}

.single-services-box { 
    padding: 70px 40px 65px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 30px 50px rgba(20, 42, 104, 0.06);
    text-align: center;
    margin-top: 30px;

	.icon { 
        width: 80px;
        height: 80px;
        border: 1px solid #C8CBF5;
        line-height: 80px;
        border-radius: 10px;
        margin: 0 auto;
        margin-bottom: 30px;
        transition: all .3s ease-in-out;
	}

    &:hover {
        .icon {
            background: #f2f3fe;
            border-color: #f2f3fe;
        }
    }

    &.sb2 {
        .icon { 
            border: 1px solid #F2E1D8;
        }
    }

    &.sb3 {

        .icon { 
            border: 1px solid #D1ECE2;
        }
    }

    &.sb2 {
        &:hover {
            .icon {
                background: #F2E1D8;
                border-color: #F2E1D8;
            }
        }
    }

    &.sb3 {
        &:hover {
            .icon { 
                background: #D1ECE2;
                border-color: #D1ECE2;
            }
        }
    }

	.content { 

		h3 { 

			a { 

			}
		}

		p { 

		}
	}
}

.service-gallery {
    margin-bottom: 30px;

    .single-service-photo {
        width: 100%;
        height: 450px;
        background-color: #eee;

        @media (max-width: 1199px) {
            height: 400px;
        }

        @media (max-width: 991px) {
            height: 300px;
        }

        @media (max-width: 767px) {
            height: 250px;
        }
    }
    
}

.service-details-wrapper {

    h2 {
        font-size: 32px;

        @media (max-width: 767px) {
            font-size: 28px;
        }

        @media (max-width: 500px) {
            font-size: 26px;
            line-height: 140%;
        }

    }

    .owl-theme {
        .owl-nav {
            div {
                left: 0;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                color: $white;
                background-color: $theme-color;
                margin: 0;
                padding: 0;
                width: 60px;
                height: 60px;
                text-align: center;
                line-height: 60px;
                font-size: 20px;
                display: inline-block;
                border-radius: 0px;

                &:hover {
                    background-color: $second-color;
                    color: #fff;
                }

                &.owl-next {
                    left: auto;
                    right: 0;
                }
            }
        }

        .owl-dots { 
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translateX(-50%);
            margin-top: 0;
            z-index: 1;
            
            .owl-dot { 
                border-width: 3px;
                border-color: transparent;
                border-style: solid;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                display: inline-block;
                line-height: 24px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                margin: 0px 5px;
                transition: all .3s ease-in-out;
    
                &:hover, &.active {
                    border-color: $theme-color;
    
                    span {
                        background-color: $theme-color;
                    }
                }
        
                span { 
                    background-color: #fff;
                    margin: 0;
                    line-height: 24px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50%;
                    margin-left: -5px;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }

    .service-details-content-wrapper {

        h1, h2, h3 , h4, h5, h6, p {
            padding-bottom: 20px;
        }
        
        img {
            margin-bottom: 20px;
        }

        blockquote, .wp-block-quote {
            background-color: $second-color;
            color: #fff;
            font-size: 26px;
            line-height: 1.4;
            font-family: $heading-font;
            font-weight: 400;
            padding: 60px;
            text-align: left;
            margin: 40px 0px;
            position: relative;
            z-index: 1;

            @media (max-width: 991px) {
                padding: 30px;
                font-size: 22px;
                line-height: 1.5;
                margin: 20px 0px;
            }
            @media (max-width: 767px) {
                padding: 30px 15px;
                font-size: 18px;
            }

            &::before {
                right: 30px;
                font-size: 110px;
                line-height: 1;
                font-family: $fa;
                position: absolute;
                content: "\f10d";
                bottom: -20px;
                color: #fff;
                z-index: -1;
                opacity: .1;
                font-weight: 900;
           }
       }
    }

    .faq-accordion {
        padding-top: 10px;

        .card {
            background-color: $theme-color;
        }
        .card-header {
            a {
                &:hover {
                    color: $white;
                }

                &::after {
                    color: $white;
                }
            }
        }
    }

}

.service-details-sidebar {
    .single-service-sidebar {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        .sidebar-title {
            margin-bottom: 25px;
    
            h3 { 
                text-transform: capitalize;
                margin-top: -7px;
            }
        }
    
        ul { 
    
            li { 
                position: relative;
    
                a { 
                    position: relative;
                    display: block;
                    background-color: #F4F6FB;
                    color: $theme-color;
                    text-align: center;
                    padding: 16px 25px 14px;
                    text-transform: capitalize;
                    font-weight: 500;
                    margin-top: 10px;

                    &:hover {
                        background-color: $second-color;
                        color: #fff;
                    }
                }
            }
        }


        .brochures-download {
            a {
                display: block;
                margin-top: 10px;
                text-align: center;
                font-weight: 500;
                text-transform: capitalize;

                i {
                    margin: 0;
                    margin-right: 10px;
                    display: inline-block;
                }

                &:first-child {
                    background-color: #000;
                    border-color: #000;
                    color: #fff;
                }

                &:last-child {
                    background-color: $second-color;
                    border-color: $second-color;
                    color: #fff;
                }
            }
        }


        .contact-us {

            .single-contact-info { 
                overflow: hidden;
                margin-bottom: 20px;
    
                &:last-child {
                    margin-bottom: 0;
                }
    
    
                .icon { 
                    float: left;
                    margin-right: 15px;
                    overflow: hidden;
                }
            
                .contact-info { 
                    overflow: hidden;
    
                    span { 
            
                    }
            
                    p { 
                        font-weight: 600;
                        color: $theme-color;
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

.service-item-card { 
    position: relative;
    z-index: 1;
    background: #1F2124;
    border-radius: 5px;
    padding: 40px;
    padding-bottom: 35px;
    overflow: hidden;

	.service-number { 
        position: absolute;
        right: 0px;
        padding-right: 50px;
        top: 30px;
        font-size: 30px;
        font-weight: 700;
        color: rgba($color: #fff, $alpha: 0.3);
        opacity: .2;
        transition: all .3s ease-in-out;

        &::before {
            position: absolute;
            right: -0px;
            top: 50%;
            width: 40px;
            height: 1px;
            content: "";
            background-color: $text-color-black;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
        }
	}

    &:hover {
        .service-number { 
            color: $text-color-black;
            opacity: 1;

            &::before { 
                opacity: 1;
                visibility: visible;
            }
        }
        .icon {
            color: #161616;
        }

        .contents { 

            h4, p { 
                color: #161616;
            }
        }

        &::after, &::before { 
            opacity: 1;
            visibility: visible;
        }

        &::before {
            width: 100%;
            z-index: -1;
        }
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #FFC21A;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease-in-out;
        left: 0;
        top: 0;
        z-index: -2;
    }

    &::before {
        position: absolute;
        width: 0%;
        height: 100%;
        content: "";
        background-color: $theme-color-5;
        opacity: 0;
        visibility: hidden;
        transition: all .4s ease-in-out;
        left: 0;
        top: 0;
        z-index: -1;
        -webkit-clip-path: polygon(0 0, 75% 0, 36% 100%, 0% 100%);
        clip-path: polygon(0 0, 75% 0, 36% 100%, 0% 100%);
    }

	.icon { 
        color: $theme-color-5;
        font-size: 54px;
        line-height: 1;
	}

	.contents { 

		h4 { 
            margin: 20px 0px;
            color: #fff;
		}

		p { 
            color: #eee;
		}
	}
}