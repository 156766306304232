/* ----------------------------------
	About Us Section Styles
 ------------------------------------ */
 .img-block-pop-video { 
	 position: relative;

	@media (max-width: 1200px) {
		margin-bottom: 60px;
	}

	.popup-video-block { 
		width: 230px;
		height: 280px;
		background: #eee;
		border: 15px solid #fff;
		position: relative;
		z-index: 1;
		margin-top: -250px;
		margin-left: -80px;

		@media (max-width: 1200px) {
			margin-left: 0;
		}

		&::before {
			position: absolute;
			width: 80%;
			height: 80%;
			background: #fff;
			opacity: 0.3;
			content: "";
			z-index: -1;
		}

		.video-play-btn { 

			.popup-video { 
				font-size: 30px;
				line-height: 1;
				color: #fff;
			}
		}
	}
}

.tab-inner-contents { 
	clear: both;
	overflow: hidden;

	.img-box { 
		float: left;
		overflow: hidden;
		margin-right: 20px;
	}

	.checked-features-list { 
		float: left;
		overflow: hidden;
	}
}

.tab-content-block {
	margin-top: 30px;

	.nav-pills {
		position: relative;
		margin-bottom: 30px !important;

		&::after {
			position: absolute;
			height: 1px;
			width: 75%;
			background-color: #EBEDF2;
			content: "";
			left: 0;
			bottom: 0;
			z-index: -1;

			@media (max-width: 767px) {
				width: 90%;
			}

			@media (max-width: 500px) {
				width: 100%;
			}
		}

		.nav-link {
			font-size: 18px;
			font-weight: 700;
			color: $theme-color;
			text-transform: uppercase;
			position: relative;
			z-index: 1;
			font-family: $heading-font;

			@media (max-width: 430px) {
				font-size: 16px;
				padding: 8px 14px;
			}
			
			&::after {
				position: absolute;
				height: 6px;
				width: 100%;
				content: "";
				left: 0;
				bottom: 0;
				z-index: -1;
				opacity: 0;
				background: transparent;
				transform: translateY(2.5px);
				transition: all .3s ease-in-out;
				visibility: hidden;
			}

			&.active {
				color: $theme-color;
				background: transparent;

				&::after {
					opacity: 1;
					visibility: visible;
					background: $second-color;
				}
			}
		}
	}
}

.checked-features-list {

    &.style-2 {
        li {
            position: relative;

            &:hover {
                &::before {
                    color: $theme-color-4;
                }
            }

            &::before {
                content: "\f058";
                color: #D4D6F5;
                font-weight: 700;
                transition: all .3s ease-in-out;
            }
        }
    }

    &.theme-color-4 {
        li {
            position: relative;

            &:hover {
                &::before {
                    color: $theme-color-4;
                }
            }

            &::before {
                content: "\f058";
                color: $theme-color-5;
                transition: all .3s ease-in-out;
            }
        }
    }

	li {
		font-size: 16px;
		color: #53545A;
		margin-top: 10px;
		padding-left: 30px;
		
		&::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "\f14a";
            font-family: $fa;
			font-weight: 300;
            color: $second-color;
			font-size: 18px;
        }
	}
}

.about-cover-bg {
	height: 570px;
	position: relative;

	@media (max-width: 991px) {
		height: 450px;
	}

	@media (max-width: 767px) {
		height: 350px;
	}

	.our-experience-years {
		position: absolute;
		right: 0;
		bottom: 0;
		padding: 0 40px 10px;
		background-color: #fff;
		text-align: center;

		&.our-experience-years_2{
			.video-play-btn { 

				.popup-video { 
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50px;
					height: 50px;
					background: rgba(255, 94, 20, 0.15);
					border-radius: 50%;
					font-size: 14px;
					line-height: 48px;
					border: 5px solid rgba(255, 94, 20, 0.15);
					text-align: center;
					margin-right: 20px;
					float: left;
					color: $second-color;
					animation-name: playVideo1;
					@media (max-width: 500px) {
						float: none;
						display: block;
						margin-bottom: 10px;
					 }
				}
	
				span { 
					line-height: 1.5;
				}
			}
		}
		&.our-experience-years__3{
			padding:40px;
		}
		.year-outline { 
			// background-image: url('../img/outline.jpg');
			background-repeat: no-repeat;
			background-position: center bottom;
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;

			h2 { 
				font-size: 115px;
				margin-bottom: 0;
				line-height: 1;
			}
		}
	
		p { 
			color: $second-color;
			font-weight: 700;
	
			span { 
				color: $theme-color;
			}
		}
	}
}

.about-banner-wrapper {
	position: relative;
}

.icon-arrow-down {
	position: absolute;
	width: 60px;
	height: 60px;
	background: #fff;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	content: "";
	border-radius: 50%;
	line-height: 60px;
	text-align: center;
	color: $second-color;
	transition: all .3s ease-in-out;
	margin-bottom: -30px;
	z-index: 2;

	.rotate-icon {
		transform: rotate(180deg);
	}

	&:hover {
		background-color: $second-color;
		color: $white;
	}
}

.single-timeline-box { 
	margin-top: 40px;

	span { 
		color: $second-color;
		font-size: 16px;
		font-weight: 500;
	}

	h6 { 

	}

	p { 
		margin-top: 25px;
	}
}

.time-line-icon { 

	.single-icon { 
		width: 70px;
		height: 70px;
		background: #fff1eb;
		position: relative;
		border-radius: 50%;
		line-height: 70px;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 130px;

		&:last-of-type {
			&::before { 
				display: none;
			}
		}

		&::before {
			position: absolute;
			left: 35px;
			top: 0;
			content: "";
			border: 1px dashed #d4d4d5;
			width: 1px;
			height: 200px;
			z-index: -1;
			transform: translateX(-50%);
		}
	}
}

.icon-features-item { 

    .icon { 
        width: 60px;
        height: 60px;
        border: 1px solid #C8CBF5;
        line-height: 50px;
        border-radius: 10px;
        transition: all .3s ease-in-out;
        float: left;
        overflow: hidden;
        margin-right: 30px;
        text-align: center;
	}

    &:hover {
        .icon {
            background: #C8CBF5;
            border-color: #C8CBF5;
        }
    }

	.content { 
        overflow: hidden;

		h4 { 
            line-height: 1;
		}

        @media (max-width: 991px) {
            br {
                display: none;
            }
        }
	}
}

.about-company-img {
    position: relative;

    @media (max-width: 991px) {
        max-width: 80%;
        display: block;
        margin: 0px auto;
    }

    .about-element {
        position: absolute;
        left: -70px;
        bottom: 100px;

        @media (max-width: 991px) {
            left: -15px;
            bottom: 50px;
        }
    }
}

.experience-video-play { 
    padding: 25px 0px;
    border-top: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
    margin: 30px 0px;

    
	.year-experience-about { 
        padding-right: 40px;
        border-right: 1px solid #eee;
        margin-right: 40px;

        @media (max-width: 767px) {
            padding-right: 20px;
            margin-right: 20px;
         }

		.year { 
            margin-right: 10px;

			h3 { 
                font-size: 50px;
                line-height: 1;
                margin-bottom: 0;
			}
		}

		.text { 

			p {
                padding-top: 4px;
                line-height: 1.4;
			}
		}
	}

	.video-popup-btn { 
        padding-right: 50px;

        @media (max-width: 767px) {
            padding-right: 10px;
         }

		.video-play-btn { 

			.popup-video { 
                width: 50px;
                height: 50px;
                border-radius: 50%;
                font-size: 14px;
                line-height: 48px;
                border: 1px solid $theme-color-5;
                text-align: center;
                margin-right: 20px;
                float: left;
                color: $theme-color-5;

                @media (max-width: 500px) {
                    float: none;
                    display: block;
                    margin-bottom: 10px;
                 }

                &:hover {
                    color: $text-color-black;
                    border-color: $text-color-black;
                }
			}

			span { 
                line-height: 1.5;
			}
		}
	}
}

.global-title {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        left: 0;
        height: 1px;
        width: 100%;
        content: "";
        background: #eee;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }

    h4 {
        background: #fff;
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        padding-right: 15px;
        margin-bottom: 0;
    }

}

.border-left {
    border-left: 2px solid $theme-color-5;
    padding-left: 20px;
    margin-top: 15px;
}

.our-achievement-skill-wrapper {
    ul {
        li {
            display: inline-block;
            font-weight: 600;
            position: relative;
            font-size: 18px;
            color: $text-color-black;
            text-transform: uppercase;
            margin-right: 15px;
            padding-right: 15px;
            line-height: 1;

            &::before {
                position: absolute;
                right: 0;
                height: 4px;
                width: 4px;
                border-radius: 50%;
                content: "";
                background: $theme-color-5;
                bottom: 5px;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.brand-client-img {
    position: relative;

    img {
        @media (max-width: 991px) {
            max-width: 70%;
        }

        @media (max-width: 600px) {
            max-width: 80%;
        }
    }
}

.company-year {
    background: $theme-color-5;
    position: absolute;
    padding: 20px 40px;
    border-radius: 10px;
    display: inline-block;
    bottom: 100px;
    right: -60px;
    transform: rotate(-90deg);

    @media (max-width: 1191px) {
        right: -20px;
    }

    h2 {
        font-size: 70px;
        line-height: 1;
        color: $text-color-black;
        margin-bottom: 0;

        @media (max-width: 600px) {
            font-size: 48px;
        }
    }

    p {
        color: $text-color-black;
        position: relative;
        z-index: 1;
        background: $theme-color-5;

        &::before {
            position: absolute;
            right: 0;
            height: 1px;
            width: 50%;
            content: "";
            background: $text-color-black;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }
    }
}

.agency-img {
    @media (max-width: 991px) {
        max-width: 70%;
        margin: 0px auto;
    }

    @media (max-width: 500px) {
        max-width: 80%;
        margin: 0px auto;
    }

}

.about_content{
	position: relative;
	.icon{
		margin-right: 35px;
		margin-left: 20px;
		img{
			width: 40px;
			height: 40px;
			max-width: none !important;
		}
	}
	.content{
		margin-top: 30px;
		h4{
			font-family: $text-font;
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 27px;
			color:$second-color;
			margin-bottom: 10px;
		}
	}
	.element__img{
		top: 20%;
		left: 0%;
		position: absolute;
	}
}