.single-work-process-item {
    width: 100%;
    max-width: 300px;
    position: relative;
    margin-top: 30px;

    @media (max-width: 1050px) {
        max-width: 280px;
    }

    
    @media (max-width: 767px) {
        text-align: center;
        margin: 0px auto;
        padding-top: 30px;
        max-width: 100%;
    }

	.icon { 
        font-size: 60px;
        line-height: 1;
        color: $second-color;
        transition: all .3s ease-in-out;
        margin-bottom: 25px;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
	}

	.contents { 
		span { 
            font-size: 16px;
            line-height: 1;
            color: $theme-color;
            text-transform: uppercase;
		}

		h5 { 
            font-size: 20px;
            margin-bottom: 10px;
		}

		p { 
            font-size: 15px;
		}
	}
}

.single-icon-circle-item { 
    clear: both;
    overflow: hidden;
    margin-top: 40px;
    padding-right: 25px;

    @media (max-width: 500px) {
        padding-right: 0;
    }

	.icon { 
        float: left;
        overflow: hidden;
        width: 100px;
        height: 100px;
        background: #fff;
        border: 1px solid #ebedf2;
        border-radius: 50%;
        color: $second-color;
        text-align: center;
        font-size: 40px;
        line-height: 110px;
        position: relative;
        box-sizing: border-box;
        margin-right: 25px;
        transition: all .4s ease-in-out;

        @media (max-width: 500px) {
            float: inherit;
            margin-bottom: 20px;
        }

        &:hover {
            color: $theme-color;
        }

        &::before {
            position: absolute;
            border: 5px solid $second-color;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            left: 0;
            top: 0;
        }
	}

	.contents { 
        overflow: hidden;

		h3 { 

		}

		p { 

		}
	}
}

.why-choice-us {
    background-image: radial-gradient(#E6E6E6 1px, transparent 0);
    background-size: 15px 15px;
    background-position: -17px ​-22px;
    position: relative;

    .why-choice-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 47%;
        height: 100%;
        content: "";
    }
}

.features-list {
    .single-icon-feature { 
        box-shadow: 0px 20px 100px 0px rgba(58, 65, 111, 0.1);
        border-width: 4px;
        border-color: #fff;
        border-style: solid;
        background-color: #fff;
        box-sizing: border-box;
        padding: 35px;
        margin-bottom: 30px;
        transition: all .3s ease-in-out;
        display: flex;
        align-items: center;

        &:hover, &.active {
            border-color: $theme-color;

            i {
                color: $theme-color;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .icon { 
            font-size: 75px;
            margin-right: 25px;
            line-height: 1;
        }

        .content { 
            overflow: hidden;

            h3 { 

            }

            p { 
                font-size: 15px;
                line-height: 2;
            }
        }
    }
}

.single-icon-border-box { 
    border: 1px solid #EBEDF2;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    @media (max-width: 1191px) {
        padding: 25px;
    }

	.icon { 
        float: left;
        overflow: hidden;
        font-size: 70px;
        line-height: 1;
        color: $second-color;
        margin-right: 20px;
	}

	.content { 
        overflow: hidden;

		h6 {
            span {
                display: block;
            }
		}
	}
}
.core__feature__wrapper{
    padding-top: 120px;
    @media (max-width: 1191px) {
        padding-top: 100px ;
    }

    @media (max-width: 767px) {
        padding-top: 80px ;
    }

}
.feature__card{
    overflow: hidden;
    background: $white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
    padding: 30px;
    position: relative;
    border-radius: 4px;
    .icon{
        margin-bottom: 30px;
    }
    .core__content{
        h4{
            font-family: $text-font;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        p{
            font-family: $body-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .vector__element{
        transition: all 0.4s ease-in-out;
        position: absolute;
        top: -30%;
        right: -10%;
        transform: rotate(-17.23deg);
    }
    .vector__element_2{
        transition: all 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -30%;
        right: -10%;
        transform: rotate(-17.23deg);
    }
    &:hover{
        .vector__elemen{
            visibility: hidden;
            opacity: 0;
        }
        .vector__element_2{
            visibility: visible;
            opacity: 1;
        }
    }
}