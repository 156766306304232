.single-blog-card {
    border: 1px solid #EBEDF2;
    position: relative;
    margin-top: 40px;

    &:hover {
        .blog-featured-img {
            &::before { 
                opacity: .5;
                visibility: visible;
                left: 0;
                right: auto;
                width: 100%;
            } 
        }
    }

	.blog-featured-img { 
        height: 230px;
        background-color: #eee;
        background-position: center;
        overflow: hidden;

        &::before {
            position: absolute;
            top: 0;
            content: "";
            background: $theme-color;
            opacity: 0;
            visibility: hidden;
            width: 0%;
            height: 100%;
            right: 0;
            transition: all .4s ease-in-out;
        }
	}

	.contents {
        padding: 30px;
        padding-top: 0;
        margin-top: -30px;

		.post-metabar {
            border: 1px solid #EBEDF2;
            display: flex;
            align-items: center;
            padding: 8px 20px;
            position: relative;
            background-color: #fff;
            margin-bottom: 15px;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                width: 1px;
                height: 100%;
                content: "";
                transform: translateX(-50%);
                background: #DCDFE8;
            }

			.post-author {

				.author-img { 
                    width: 34px;
                    height: 34px;
                    border-radius: 50px;
                    float: left;
                    margin-right: 10px;
				}

				a { 
                    text-transform: uppercase;
                    font-size: 14px;
                    color: $theme-color;
                    font-weight: 700;
                    overflow: hidden;
                    line-height: 34px;

                    &:hover {
                        color: $second-color;
                    }
				}
			}

			.post-date { 
                i {
                    color: $second-color;
                    margin-right: 5px;
                    font-size: 16px;
                }

				a { 
                    text-transform: uppercase;
                    font-size: 14px;
                    color: $theme-color;
                    font-weight: 700;

                    &:hover {
                        color: $second-color;
                    }
				}
			}
		}

		h3 { 

            &:hover {
                color: $second-color;
            }
		}

		.read-more-link {
            color: $second-color;
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            text-transform: uppercase;
            margin-top: 30px;
            display: block;

            &:hover {
                color: $theme-color;
            }
		}
	}
}

.single-news-card { 
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 30px 50px rgba(6, 20, 54, 0.1);
    margin-top: 30px;

	.post-thumb { 
        width: 100%;
        height: 240px;
        position: relative;
	}

	.content { 
        padding: 30px;

		.post-meta {
            color: #53545A;
            i {
                margin-right: 5px;
            }
		}

		h3 { 
            font-size: 23px;
            color: #171717;
            font-weight: 600;
            text-transform: capitalize;

            &:hover {
                color: $theme-color-4;
            }
		}

		.post-btn-link { 

			.read-btn { 
                margin-top: 15px;
                font-size: 15px;
                line-height: 1;
                color: #707070;
                font-weight: 400;

                &:hover {
                    color: $theme-color-4;
                }

                i {
                    margin-left: 8px;
                }
			}
		}
	}
}

.latest-news-card { 
    position: relative;
    background: #FBFBFB;
    margin-top: 30px;

	.post-thumb { 
        width: 100%;
        height: 240px;
	}

	.content { 
        padding: 25px 30px;

		h3 { 
            font-size: 22px;
            font-weight: 500;
            line-height: 32px;

            &:hover {
                a {
                    color: $theme-color-5;
                }
            }
		}

        .post-cat { 
            display: inline-block;
            margin-bottom: 8px;

            &:hover {
                i, a {
                    color: $theme-color-5;
                }
            }
            
            i, a {
                transition: all .3s ease-in-out;
                font-size: 14px;
                line-height: 1;
                text-transform: uppercase;
                font-weight: 400 !important;
                color: #53545a !important;
            }
        }

		.post-meta { 
            text-transform: uppercase;
            font-size: 14px;

			.post-date { 

			}

			.post-comment { 
			}
		}
	}
}

.blog-wrapper {
    .border-wrap {
        padding: 40px;
        @media (max-width: 767px) {
            padding: 50px 15px;
        }
        .single-blog-post {
            .post-content {
                padding: 0px;
           }
       }
    }

    .single-blog-post {
        margin-bottom: 40px;
        position: relative;

        &.post-details {
            .post-content {
                border: none;

                img {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                h1 {
                    font-size: 36px;
                    line-height: 1.4;
                }

                @media (max-width: 767px) {
                    h1 {
                        font-size: 28px;
                   }
                    h3 {
                        font-size: 20px;
                   }
               }
            }

            blockquote, .wp-block-quote {
                background-color: $second-color;
                color: #fff;
                font-size: 26px;
                line-height: 1.4;
                font-family: $heading-font;
                font-weight: 400;
                padding: 60px;
                text-align: left;
                margin: 40px 0px;
                position: relative;
                z-index: 1;
                @media (max-width: 767px) {
                    padding: 30px 15px;
                    font-size: 18px;
                    line-height: 1.5;
               }
                &::before {
                    right: 30px;
                    font-size: 110px;
                    line-height: 1;
                    font-family: $fa;
                    position: absolute;
                    content: "\f10d";
                    bottom: -20px;
                    color: #fff;
                    z-index: -1;
                    opacity: .1;
                    font-weight: 900;
               }
           }
       }
        &.format-video, &.category-video-post {
            .post-featured-thumb {
                z-index: 1;
                &::before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    content: "";
                    background-color: #282835;
                    opacity: .3;
                    z-index: -1;
               }
           }
       }
        &.quote-post, &.format-quote {
            .post-content {
                position: relative;
                background-color: $theme-color-2;
                background-image: url('../img/quotepost.png');
           }
            .quote-content {
                overflow: hidden;
                @media (max-width: 585px) {
                    text-align: center;
                    font-size: 24px;
               }
                .icon {
                    font-size: 76px;
                    line-height: 80px;
                    float: left;
                    overflow: hidden;
                    margin-right: 30px;
                    @media (max-width: 585px) {
                        float: none;
                        margin-bottom: 10px;
                        margin-right: 0;
                        font-size: 50px;
                        line-height: 60px;
                   }
               }
                .quote-text {
                    overflow: hidden;
               }
                h2 {
                    margin-top: -2px;
               }
           }
        }

        &:last-child {
            margin-bottom: 0;
        }

        .post-featured-thumb {
            height: 380px;
            width: 100%;
            position: relative;
            background-color: #f2f2f2;
            @media (max-width: 991px) {
                height: 350px;
           }
            @media (max-width: 767px) {
                height: 280px;
           }
            .video-play-btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                .play-video {
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    font-size: 18px;
               }
           }
        }

        .post-content {
            padding: 50px;
            border: 2px solid #ededed;
            border-top: 0px;
            overflow: hidden;

            h2 {
                &:hover {
                    a {
                        color: $theme-color;
                    }
                }
            }
            @media (max-width: 767px) {
                padding: 50px 10px;
                h2 {
                    font-size: 26px;
                    line-height: 1.4;
               }
           }
            @media (max-width: 414px) {
                h2 {
                    font-size: 22px;
                }
           }
            .post-cat {
                a {
                    color: #fff;
                    display: inline-block;
                    background-color: $theme-color-2;
                    padding: 10px 18px 7px;
                    font-size: 14px;
                    font-weight: 700;
                    transition: all .3s ease-in-out;
                    margin-bottom: 20px;
                    @media (max-width: 767px) {
                        font-size: 12px;
                   }
                    &:hover {
                        background-color: $theme-color;
                   }
               }
           }
            ul {
                margin-bottom: 20px;
                margin-top: 15px;
                @media (max-width: 767px) {
                    margin-left: 0;
               }
                li {
                    font-size: 16px;
                    color: #727475;
                    font-weight: 700;
                    margin-top: 10px;
                    padding-left: 30px;
                    display: block;
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "\f00c";
                        font-family: $fa;
                        font-weight: 400;
                        color: #222;
                   }
               }
            }
            p {
                margin-top: 15px;
           }
            .post-meta {
                margin-top: 10px;
                span {
                    color: #202426;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    display: inline-block;
                    margin-right: 25px;
                    @media (max-width: 767px) {
                        font-size: 13px;
                   }
                    i {
                        margin-right: 5px;
                   }
               }
           }
            .author-info {
                overflow: hidden;
                .author-img {
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    background-color: #f2f2f2;
                    margin-right: 15px;
                    float: left;
                    overflow: hidden;
                    background-position: center;
                    background-size: cover;
                    @media (max-width: 767px) {
                        margin-right: 5px;
                   }
               }
                h5 {
                    display: inline-block;
                    line-height: 1;
                    font-size: 16px;
                    margin-top: 9px;
                    @media (max-width: 767px) {
                        font-size: 14px;
                   }
               }
           }
            .post-link {
                a {
                    font-weight: 700;
                    @media (max-width: 767px) {
                        font-size: 14px;
                   }
                    i {
                        margin-right: 5px;
                        @media (max-width: 767px) {
                            margin-right: 3px;
                       }
                   }
               }
           }
       }
   }
}

.main-sidebar {
    @media (min-width: 991px) {
        margin-left: 20px;
   }
    @media (max-width: 991px) {
        margin-top: 40px;
   }
    .single-sidebar-widget {
        border: 2px solid #ededed;
        box-sizing: border-box;
        padding: 30px;
        margin-bottom: 40px;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        .wid-title {
            margin-bottom: 35px;
            position: relative;
            padding-left: 45px;

            @media (max-width: 767px) {
                margin-bottom: 25px;
            }

            &::before {
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                height: 4px;
                width: 18px;
                border-radius: 5px;
                position: absolute;
                background-color: $theme-color-2;
           }
            &::after {
                left: 22px;
                top: 50%;
                transform: translateY(-50%);
                content: "";
                height: 4px;
                width: 4px;
                border-radius: 50%;
                position: absolute;
                background-color: $theme-color-2;
           }
        }

        .social-link {
            a {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 42px;
                text-align: center;
                border-radius: 50%;
                background-color: #f2f2f2;
                font-size: 14px;
                margin-right: 5px;
                margin-bottom: 5px;

                &:hover {
                    background-color: $theme-color-2;
                    color: #fff;
                }
            }
        }
    }

    .author-box-widegts {
        .author-img {
            width: 140px;
            height: 140px;
            line-height: 140px;
            background-color: #f2f2f2;
            border-radius: 50%;
            margin: 0 auto;
            display: inline-block;
            margin-bottom: 15px;
        }

        p {
            font-size: 16px;
            margin-top: 10px;
        }

        .social-profile {
            margin-top: 15px;
            a {
                display: inline-block;
                margin-top: 10px;
                margin-right: 10px;
                transition: all .3s ease-in-out;
                
                &:hover {
                    color: $theme-color-2;
                }
            }
        }
    }
}

.search_widget {
    form {
        width: 100%;
        position: relative;
        input {
            background-color: #f8f8f8;
            font-size: 15px;
            padding: 20px;
            width: 100%;
       }
        button {
            position: absolute;
            right: 0;
            top: 0;
            width: 70px;
            font-size: 18px;
            height: 100%;
            background-color: $theme-color-2;
            color: #fff;
            text-align: center;
            transition: all .3s ease-in-out;
            &:hover {
                background-color: $second-color;
           }
       }
   }
}

.instagram-gallery, .instagram_gallery {
    width: 100%;
    overflow: hidden;
    margin-left: -5px;
    .single-photo-item {
        float: left;
        margin-left: 4%;
        background-color: #f8f8f8;
        height: 80px;
        width: 29%;
        overflow: hidden;
        margin-bottom: 10px;
   }
}

.popular-posts, .popular_posts {
    .single-post-item {
        overflow: hidden;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #f2f2f2;
        &:last-child {
            margin-bottom: 0;
            border: none;
            padding-bottom: 0;
       }
        .thumb {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: #f2f2f2;
            float: left;
            overflow: hidden;
            margin-right: 20px;
       }
        .post-content {
            overflow: hidden;
            h5 {
                font-size: 16px;
                line-height: 22px;
                &:hover {
                    color: $theme-color-2;
               }
           }
            .post-date {
                margin-top: 5px;
                font-weight: 700;
                font-size: 14px;
                i {
                    margin-right: 7px;
               }
           }
       }
   }
}

.tagcloud {
    a {
        display: inline-block;
        padding: 12px 22px 10px;
        font-size: 14px;
        font-weight: 700;
        background: #f8f8f8;
        margin-right: 5px;
        margin-bottom: 8px;
        text-transform: uppercase;

        &:last-child {
            margin-right: 0;
        }
        
        &:hover {
            background-color: $theme-color-2;
            color: #fff;
       }
   }
}

.widget_categories {
    ul {
        li {
            display: block;

            &:last-child {
                a {
                    margin-bottom: 0;
               }
            }

            a {
                position: relative;
                background: #f8f8f8;
                padding: 20px 30px;
                line-height: 1;
                font-size: 14px;
                font-weight: 700;
                display: block;
                transition: all .3s ease-in-out;
                margin-bottom: 10px;
                text-transform: uppercase;

                &:hover {
                    color: $theme-color-2;
                }                

                span {
                    position: absolute;
                    width: 60px;
                    line-height: 53px;
                    height: 100%;
                    content: "";
                    background-color: #e3e3e3;
                    right: 0;
                    top: 0;
                    text-align: center;
                }
            }
        }
    }
}

.error-content { 

	h1 { 
        font-weight: 700;
        font-size: 300px;
        line-height: 1;
        color: $second-color;
        margin-top: -60px;

        @media (max-width: 1200px) {
            font-size: 250px;
            margin-top: -50px;
        }

        @media (max-width: 991px) {
            font-size: 200px;
            margin-top: -40px;
        }

        @media (max-width: 767px) {
            font-size: 160px;
            margin-top: -30px;
        }
	}

	h2 { 
        color: $theme-color;

        @media (max-width: 767px) {
            font-size: 24px;
        }
	}

	p { 

	}

	.theme-btn { 
        margin-top: 40px;

        @media (max-width: 767px) {
            margin-top: 25px;
            font-size: 12px;
        }
	}
}

.causes-page-nav, .page-nav-wrap {
    ul {
        li {
            display: inline-block;
            a {
                display: inline-block;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 50%;
                background: #f6f6f6;
                font-weight: 700;
                transition: all 0.3s ease-in-out;
                margin: 0 2px;
                @media (max-width: 767px) {
                    margin-top: 10px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 14px;
               }
                i {
                    margin-top: 2px;
               }
                &:hover {
                    background-color: $theme-color-2;
                    color: #fff;
               }
           }
       }
   }
}

.social-share {
    a {
        margin-left: 10px;
   }
}

.tag-share-wrap {
    padding-bottom: 50px;
    h4 {
        margin-bottom: 25px;
        @media (max-width: 767px) {
            margin-bottom: 10px;
            font-size: 18px;
       }
   }
    .tagcloud {
        @media (max-width: 767px) {
            margin-bottom: 20px;
       }
   }
}

.related-post-wrap, .related_posts_wrap {
    border-top: 1px solid #e2e2e2;
    padding-top: 40px;
    .single-related-post {
        margin-top: 33px;
        box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
        &:hover {
            h4 {
                color: $theme-color-2;
           }
       }
        .featured-thumb {
            height: 280px;
            background-color: #f8f8f8;
            @media (max-width: 767px) {
                height: 200px;
           }
       }
        .post-content {
            padding: 35px;
            @media (max-width: 767px) {
                padding: 30px 15px;
            }
            .post-date {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 14px;
                color: #202426;
                i {
                    margin-right: 6px;
               }
           }
            h4 {
                margin-bottom: 15px;
                @media (max-width: 767px) {
                    font-size: 18px;
               }
           }
            p {
                font-size: 15px;
                line-height: 26px;
                color: #727475;
           }
       }
   }
}

.comments-section-wrap {
    overflow: hidden;
}

.comments-heading, .related-post-wrap, .comment-form-wrap {
    h3 {
        font-size: 26px;
        @media (max-width: 767px) {
            font-size: 20px;
       }
   }
}

.comments-item-list {
    .replay-comment {
        margin-left: 100px;
        @media (max-width: 767px) {
            margin-left: 15px;
       }
   }
    .single-comment-item {
        margin-top: 30px;
        .author-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            float: left;
            overflow: hidden;
            margin-right: 30px;
            @media (max-width: 585px) {
                float: none;
                margin-bottom: 20px;
           }
       }
        .author-info-comment {
            overflow: auto;
            padding-bottom: 25px;
            border-bottom: 1px solid #e2e2e2;
            .info {
                position: relative;
                @media (max-width: 767px) {
                    h5 {
                        font-size: 16px;
                   }
               }
           }
            span {
                color: $theme-color-2;
           }
            .theme-btn {
                padding: 10px 18px;
                font-size: 14px;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                color: $theme-color;
                border-radius: 30px;

                &:hover {
                    color: #fff;
                }

                @media (max-width: 767px) {
                    font-size: 12px;
                }
                i {
                    margin-right: 5px;
                    margin-left: 0;
               }
           }
            .comment-text {
                margin-top: 15px;
           }
       }
   }
}

.comment-form {
    background-color: #f0f0f0;
    padding: 50px;
    width: 100%;
    margin-top: 30px;
    @media (max-width: 767px) {
        padding: 30px;
   }
    @media (max-width: 585px) {
        padding: 30px 15px;
   }
    input, textarea {
        margin-bottom: 20px;
        padding: 24px 30px;
        line-height: 1;
        font-size: 14px;
        width: 100%;
        @media (max-width: 585px) {
            padding: 15px;
       }
   }
    textarea {
        height: 200px;
        @media (max-width: 767px) {
            height: 120px;
       }
   }
    .submit-btn {
        display: inline-block;
        background-color: $theme-color-2;
        color: #fff;
        line-height: 1;
        font-size: 700;
        text-transform: capitalize;
        padding: 24px 60px;
        font-family: $heading-font;
        transition: all .3s ease-in-out;
        margin-top: 10px;
        @media (max-width: 767px) {
            padding: 20px 40px;
       }
        @media (max-width: 585px) {
            padding: 15px 40px;
            font-size: 14px;
       }
        &:hover {
            background-color: $theme-color;
       }
        i {
            margin-right: 10px;
       }
   }
}

.border-wrap {
    border: 2px solid #ededed;    
}

.single__bloge{
    padding: 20px;
    padding-bottom: 40px;
    position: relative;
    background: rgba(255, 94, 20, 0.02);
    box-shadow: 0px 8px 20px rgba(1, 1, 28, 0.1);
    z-index: -1;
    .icons{
        margin-bottom: 30px;
    }
    .content{
        h4{
            font-family: $text-font;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 10px;
        }
        p{
            font-family: $body-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .vector__icons{
        z-index: -1;
        position: absolute;
        top: 0%;
        left: 0%;
    }
}
.icons__fun{
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: $second-color;
    img{
        width: 40px;
        height: auto;
    }
}
.blog__widget{
    position: relative;
    .blog__content{
        z-index: 4;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        span{
            color: $white;
            padding: 5px 10px;
            background-color: $second-color;
            display: inline;
        }
        .author__date{
            margin-top: 25px;
            a{
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: $white;
                i{
                    color: $white;
                    margin-right: 10px;
                }
                &:nth-child(2){
                    margin-left: 20px;
                }
            }
        }
        h4{
            margin: 10px 0px;
            font-family: $text-font;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: $white;
        }
        a{
            color: $second-color;
            font-family: $body-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            i{
                margin-left: 10px;
            }
            transition: all 0.4s ease-in-out;
            &:hover{
                color: $white;
            }
        }
        &.blog__content_2{
             p{
                color: $title-color-2;
            }
            p{
                margin-bottom: 10px;
            }
            h4{
                color: $title-color-2;
            }
            .author__date{
                a{
                    color: $title-color-2;
                    i{
                        color: $title-color-2;
                    }
                }
            }
            a{
               &:hover{
                color: $title-color-2;
               }
            }
        }
    }

}
.blog__img{
    height: 300px;
    width: auto;
    @include overlay(#01011c99,0.6);
}