
/* ----------------------------------
	Contact Us Section Styles
 ------------------------------------ */
 .contact-form {
	padding: 40px;
	background-color: #fff;
	padding-top: 35px;

	@media (max-width: 585px) {
		padding-top: 20px;           
	}

	.single-personal-info {
		margin-bottom: 25px;
	}

	h3, h2, h1 {
		margin-bottom: 30px;
	}

	input, textarea {
		width: 100%;
		background: #F8F9FC;                        
		line-height: 1;
		padding: 25px 30px;
		border: 0px;
		
		@media (max-width: 585px) {
            padding: 15px;
			font-size: 13px;            
        }
		
	}
	textarea {
		height: 220px;

		@media (max-width: 767px) {
            height: 160px;
        }
	}

	label {
		color: #202426;
		font-weight: 700;
		font-size: 14px;
		margin-bottom: 10px;
		text-transform: capitalize;

		span {
			color: #727475;
			margin-left: 5px;
		}
	}

	input[type='submit'], .submit-btn {
		background: $second-color;
		color: #fff;
		font-weight: 700;
		line-height: 1;
		padding: 25px;
		transition: all .3s ease-in-out;
		width: 230px;
		text-transform: uppercase;
		font-family: $heading-font;
		font-size: 14px;

		&.active, &:hover {
			background-color: $theme-color;                            
		}

		i {
			margin-right: 8px;
		}

		@media (max-width: 585px) {
			padding: 15px 35px;
			font-size: 14px;
			font-weight: 600;
			width: auto;        
		}

		@media (max-width: 400px) {
			width: 100%;
		}

	}
}

.contact-us-wrapper {
	background-color: #F9FAFE;
}

.contact-us-sidebar {
	background-color: #fff;
	padding: 40px;
	padding-top: 30px;

	@media (min-width: 991px) and (max-width: 1115px) {
		display: block;
		text-align: center;
	}

	.contact-info { 
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 30px;

		@media (min-width: 991px) and (max-width: 1115px) {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		&::after {
			background: #EBEDF2;
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;

		}
	}

	h2 { 

	}

	.live-chat {

		a {
			text-decoration: underline;
			color: $second-color;
			text-transform: uppercase;
			margin-top: 10px;
			display: block;

			@media (min-width: 991px) and (max-width: 1115px) {
				margin-top: 0;
			}

			i {
				font-size: 22px;
				margin-right: 10px;
				display: inline-block;
				margin-top: 10px;
			}
		}

		p {

		}
	}
}

.single-info { 
	overflow: hidden;
	clear: both;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 30px;



	@media (min-width: 991px) and (max-width: 1115px) {
		display: block;
		text-align: center;
		margin-top: 20px;
	}

	.icon { 
		float: left;
		overflow: hidden;
		font-size: 42px;
		line-height: 1;
		color: $second-color;
		margin-right: 20px;

		@media (max-width: 1191px) {
			font-size: 36px;
		}

		

		@media (min-width: 991px) and (max-width: 1115px) {
			float: inherit;
			display: block;
		}

		
	}

	.text { 
		overflow: hidden;

		span { 
			display: block;
		}

		h5 { 
			margin-bottom: 0;

			@media (max-width: 1191px) {
				font-size: 18px;
			}
		}
	}
}

.contact-map-wrap {
	iframe {
		height: 450px;

		@media (max-width: 991px) {
			height: 300px;
		}
	}
}

.homepage-contact-from {

    @media (min-width: 1600px) {
        margin-right: -100px;
    }

    @media (min-width: 1800px) {
        margin-right: -200px;
    }

    form {
        .single-personal-info {
            margin-bottom: 30px;

            input, textarea {
                width: 100%;
                background: #404CEB;                        
                line-height: 1;
                padding: 20px 30px;
                border: 0px;
                color: #fff;
                border-radius: 5px;
                text-transform: capitalize;

                &::placeholder {
                    color: #fff;
                }
                
                @media (max-width: 585px) {
                    padding: 15px;
                    font-size: 13px;            
                }
                
            }

            textarea {
                height: 180px;
        
                @media (max-width: 767px) {
                    height: 100px;
                }
            }
        }


        input[type='submit'], .submit-btn {
            background: $white;
            color: $theme-color;
            font-weight: 700;
            line-height: 1;
            padding: 25px;
            transition: all .3s ease-in-out;
            width: 200px;
            text-transform: uppercase;
            font-family: $heading-font;
            font-size: 14px;
            border-radius: 5px;
    
            &.active, &:hover {
                background-color: $theme-color;
                color: #fff;                         
            }
    
            i {
                margin-right: 8px;
            }
    
            @media (max-width: 585px) {
                padding: 15px 35px;
                font-size: 14px;
                font-weight: 600;
                width: auto;        
            }
    
            @media (max-width: 400px) {
                width: 100%;
            }
    
        }
    }
}

.contact-sec-wrapper {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        right: 0;
        top: 0;
        width: 52%;
        height: 100%;
        content: "";
        z-index: -1;
        border-top-left-radius: 50px;
        background-color: #4E5AFF;

        @media (max-width: 991px) {
            display: none;
        }
    }

    &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 30%;
        content: "";
        z-index: -2;
        background-color: #fff;

        @media (max-width: 991px) {
            display: none;
        }
    }
}

.our_faq__wrapper{
	input[type="submit"]{
		height: 60px;
		border: none;
	}
	input[type="text"]{
		height: 50px;
	}
	.form-control:focus {
        outline: 1px solid rgba(2, 2, 49, 0.1) !important;
        border: 1px solid rgba(2, 2, 49, 0.1) !important;
        box-shadow: 0 0 0;
		}
	.card__element{
		background-color: $white;
		@media( max-width:768px) {
            margin-top: 30px;
        }
		button{
			border-radius: 0px;
			height: 50px;
			width: 100%;
			border: none;
			background: $title-color-2;
			font-family: $text-font;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			transition: all 0.4s ease-in-out;
			color: $white;
			&:nth-child(2){
				background: $second-color;
			}
			&:hover{
				background-color:$second-color;
				&:nth-child(2){
					background: $title-color-2;
				}
			}
			@media( max-width:768px) {
				font-size: 16px;
				line-height: 23px;
			}
		}
		input[ type="submit"]{
			border-radius: 0px;
			background: $title-color-2;
			font-family: $text-font;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 30px;
			transition: all 0.4s ease-in-out;
			color: $white;
			&:nth-child(2){
				background: $second-color;
			}
			&:hover{
				background-color:$second-color;
				&:nth-child(2){
					background: $title-color-2;
				}
			}
		}
		input [type="radio"]{
			font-size: 20px;
			color: $second-color !important;
		}
		label{
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;
			color: #53545A;
		}
		.card__widget{
			span{
				margin-top: 50px;
				margin-bottom: 30px;
				font-family: $text-font;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 26px;
				color: $title-color-2;
				
			}
			.span_2{
				margin-top: 30px !important;
				margin-bottom: 30px !important;
			}
		}
	}
	.contact__title{
		font-family: $text-font;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
		margin: 30px 0px;
		color: $second-color;
		text-transform: capitalize;
	}
	.contact__info{
		padding: 30px;	
		background-color: #fff;
		border-radius: 4px;
		.icon{
			margin-right: 10px;
		}
		.content{
			p{
				font-family:$body-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 26px;
				color: $title-color-2;
				margin-bottom: 5px;
			}
			h5{
				font-family: $text-font;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 36px;
				color: #000000;
			}
		}
	}
	.support__element{
		h4{
			margin-top: 10px;
			font-family: $text-font;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			text-transform: capitalize;
		}
	}
}