.single-team-member {
    position: relative;
    margin-top: 40px;

    &:hover {

        .team-img { 
            
            img {
                transform: scale(1.1);
            }
        }

        .team-details-bar {

            &::after { 
                opacity: 1;
                visibility: visible;
                width: 100%;
                left: 0;
                right: auto;
            }

            .member-details {
                
                .member-data { 
                    opacity: 0;
                    visibility: visible;
                    margin-top: -25px;
                }

                .social-profile {
                    opacity: 1;
                    visibility: visible;
                    z-index: 1;
                }
            }

        }
    }


	.team-img { 
        height: 440px;
        background-color: #e8ecf8;
        overflow: hidden;
        text-align: center;

        @media (max-width: 991px) {
            height: 340px;
        }

        img {
            transition: all .4s ease-in-out;
        }
	}

	.team-details-bar {
        height: 30px;
        width: 90%;
        margin: 0 auto;
        background: #fff;
        margin-top: -50px;
        position: relative;
        overflow: hidden;
        clear: both;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        min-height: 90px;

        &::after {
            position: absolute;
            width: 0%;
            height: 1px;
            background-color: $second-color;
            content: "";
            right: 0;
            bottom: 0;
            opacity: 1;
            visibility: hidden;
            transition: all .4s ease-in-out;
        }

		.member-details {
            overflow: hidden;
            height: 30px;

			.member-data {
                transition: all .4s ease-in-out;

				span { 
                    font-size: 14px;
				}

				h3 { 
                    margin-bottom: 0;
				}
			}

			.social-profile {
                opacity: 0;
                visibility: visible;
                transition: all .4s ease-in-out;

				a { 
                    font-size: 20px;
                    margin: 0 12px;
                    color: $theme-color;

                    &:hover {
                        color: $second-color;
                    }
				}
			}
		}

		.plus-btn {
            border: 1px solid $second-color;
            color: $second-color;
            font-size: 20px;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            box-sizing: border-box;
            transition: all .3s ease-in-out;

            &:hover {
                color: #fff;
                background-color: $second-color;
            }
		}
	}
}

.team-member-details-wrapper {
    background: #F9FAFE;
    
    .container {
        margin-top: -80px;
        z-index: 3;
        position: relative;
        
        @media (max-width: 991px) {
            margin-top: 0px;
            padding-top: 100px;
        }
        @media (max-width: 767px) {
            padding-top: 80px;
        }
    }

    .icon-box {
        margin-top: 30px;
        background-color: #fff;
        padding: 85px 30px;

        @media (max-width: 576px) {
            padding: 50px 20px;
        }

        .icon {
            margin-bottom: 10px;
        }

        h2 {
            font-size: 28px;
            margin-bottom: 0;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }
    }
}

.single-team-card {
    width: 100%;
    height: 450px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 40px;
    margin-top: 30px;

    @media (max-width: 767px) {
        height: 350px;
    }

    &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #050d72 100%);
        opacity: .7;
    }

    &:hover {
        .member-info-card { 
            .member-details {
                opacity: 0;
                top: 20px;
                visibility: hidden;
            }

            .member-social-net { 
                opacity: 1;
                bottom: 0;
                visibility: visible;
            }
        }
    }

	.member-info-card { 
        overflow: hidden;
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;

		.member-details {
            position: relative;
            transition: all .4s ease-in-out;
            opacity: 1;
            visibility: inherit;
            top: 0;

			h3 { 
                color: #fff;
                margin-bottom: 0;
                text-transform: capitalize;
                font-weight: 700;
			}

			span { 
                color: #fff;
                font-size: 14px;
			}
		}

		.member-social-net { 
            opacity: 0;
            visibility: hidden;
            transition: all .4s ease-in-out;
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;

			a { 
                width: 32px;
                height: 32px;
                text-align: center;
                font-size: 12px;
                line-height: 32px;
                display: inline-block;
                margin: 0px 2px;
                background-color: #fff;
                color: #565F7B;
                border-radius: 5px;

                &:hover {
                    color: $theme-color-4;
                }
			}
		}
	}
}

.our-team-two-wrapper {
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        height: 90%;
        width: 100%;
        background-color: #F8F8F8;
        z-index: -1;
        content: "";
    }
}

.contact-form7-wrapper {
    margin-top: 30px;

    .contact-form {
        padding: 50px 30px;
    }
}

.biography-info {
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;

    p {
         margin-bottom: 30px;
    }

    .single-bio-data {
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        text-transform: capitalize;
        margin-top: 5px;

        @media (max-width: 767px) {
           width: 80%;
        }

        .key-data {
            font-weight: 500;
            position: relative;

            &::after {
                position: absolute;
                content: ":";
                right: 10px;
            }
        }
    }
}

.member-profile-wrapper {
    background-color: #fff;

    .member-profile-img {
        height: 340px;
        background-position: top;

        @media (max-width: 767px) {
            height: 300px;
        }

        @media (max-width: 585px) {
            height: 280px;
        }
    }

    .member-info { 
        max-width: 500px;
        position: relative;
        padding-bottom: 15px;

        @media (max-width: 1100px) {
            max-width: 400px;
        }

        @media (max-width: 991px) {
            max-width: 320px;
        }

        @media (max-width: 767px) {
            max-width: 450px;
            margin: 0;
        }

        @media (max-width: 576px) {
            display: block;
            max-width: 100%;
        }

        @media (min-width: 1440px) {
            &::before {
                position: absolute;
                content: "";
                right: -8%;
                top: 0;
                height: 100%;
                width: 1px;
                background: #EBEDF2;
            }                
        }

        @media (max-width: 767px) {
            padding: 30px;
        }

        h2 { 
            font-size: 36px;
            margin-bottom: 5px;

            @media (max-width: 991px) {
                font-size: 30px;
            }

            @media (max-width: 576px) {
                font-size: 26px;
            }
        }
    
        p { 
            margin-top: 30px;

            @media (max-width: 767px) {
                margin-top: 20px;
            }

            @media (max-width: 576px) {
                margin-top: 10px;
            }
        }
    
        .social-profiles { 
            margin-top: 20px;

            a { 
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                border: 1px solid #EBEDF2;
                margin-right: 10px;
                display: inline-block;
                margin-top: 10px;
            }
        }
    } 

    .member-contact-info {
        margin-right: 50px;

        @media (max-width: 767px) {
            padding: 30px 20px;
            margin: 0;
        }

        @media (max-width: 576px) {
            display: block;
            width: 100%;
            padding: 0 15px;
            padding-bottom: 50px;
        }

        @media (max-width: 1100px) {
            margin-right: 20px;
            margin-left: 20px;
        }

        .single-contact {
            margin-top: 15px;
        }

        h6 {
            margin-bottom: 5px;
        }
    }


}

.skill-bars {
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    padding-bottom: 70px;
}

.member-faces {

    &:hover {
        .single-face { 
            margin-right: 0px;
        }
    }

    .single-face { 
        display: inline-block;
        width: 38px;
        height: 38px;
        border: 3px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #7367F0;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        margin-right: -10px;
        z-index: 1;
        transition: all .4s ease-in-out;
    }
}

.single-team-member-3 { 
    text-align: center;
    position: relative;
    height: 320px;
    background-color: #eee;
    width: 100%;
    padding: 20px;
    z-index: 1;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: top center;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        background-color: $theme-color-5;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transition: all .4s;
    }

    &:hover {
        &::before { 
            opacity: .85;
            visibility: visible;
        }
        
        .member-info-card {  
            &::before, &::after { 
                opacity: 1;
                visibility: visible;
            }

            .content {
                opacity: 1;
                visibility: visible;
                &::before, &::after { 
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

	.member-info-card { 

        &::before {
            position: absolute;
            left: 20px;
            top: 20px;
            content: "";
            background-color: $text-color-black;
            width: 2px;
            height: 56px;
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
        }

        &::after {
            position: absolute;
            left: 22px;
            top: 20px;
            content: "";
            background-color: $text-color-black;
            width: 56px;
            height: 2px;
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
        }

		.content { 
            opacity: 0;
            visibility: hidden;
            transition: all .4s;
            
            &::before {
                position: absolute;
                right: 20px;
                bottom: 20px;
                content: "";
                background-color: $text-color-black;
                width: 2px;
                height: 56px;
                opacity: 0;
                visibility: hidden;
                transition: all .4s;
            }
    
            &::after {
                position: absolute;
                right: 22px;
                bottom: 20px;
                content: "";
                background-color: $text-color-black;
                width: 56px;
                height: 2px;
                opacity: 0;
                visibility: hidden;
                transition: all .4s;
            }

			h4 { 
                margin-bottom: 0px;
			}

			p { 
                text-transform: uppercase;
                font-size: 14px;
                color: $text-color-black;
                font-weight: 500;
			}

			.social-profile { 
                margin-top: 5px;

				a { 
                    margin: 0 5px;

                    &:hover {
                        color: $white;
                    }
				}
			}
		}
	}
}

.circle-border {
    z-index: 1;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        width: 550px;
        height: 550px;
        border-radius: 50%;
        background-color: #fff;
        left: -5%;
        top: -9%;
        z-index: -1;

        @media (max-width: 991px) {
            display: none;
        }
    }

    &::after {
        position: absolute;
        content: "";
        width: 166px;
        height: 166px;
        border-radius: 50%;
        background-color: $theme-color-5;
        left: 100px;
        top: 100px;
        z-index: -1;

        @media (max-width: 991px) {
            display: none;
        }
    }
}
.our_team__wrapper{
    position: relative;
    @include overlay(#01011c4d, 0.3)
}
.team__content{
    h5{
        font-family: $body-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 10px;
    }
    h2{
        font-family: $text-font;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        color: $white;
        margin-bottom: 10px;
    }
    p{
        color: $white;
        margin-bottom: 10px;
    }
    a{
        padding: 15px 30px;
        border-radius: 4px;
    }
}
.team__element{
    position: relative;
    .team_man__img{
        height: 280px;
        width: auto;
        position: relative;
        z-index: 1;
        &::after {
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.49%, #000000 100%);
        }
    }
    .team_content{
        position: absolute;
        bottom: -6%;
        left: 25%;
        z-index: 2;
        h4{
            font-family: $text-font;
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 100%;
            color: $white;
            margin-bottom: 10px;
        }
        p{
            color: $white;
            margin-bottom: 30px;
        }
        a{
            padding: 10px 20px;
            border-radius: 4px;
            visibility: hidden;
            opacity: 0;
            transition: all 0.4s ease-in-out;
        }
        
    }
    &:hover{
        .team_content{
            a{
                visibility: visible;
                opacity: 1;
            }
        }
    }
}