.promo-featured-wrapper {

    .featured-img {
        height: 556px;
        background-color: #eee;
        position: relative;
        margin-left: 60px;
        background-size: cover;
        background-position: center;
        margin-right: 60px;

        @media (max-width: 991px) {
           height: 400px;
        }

        @media (max-width: 767px) {
           height: 300px;
        }
    }

    .top-img {
        width: 180px;
        height: 180px;
        background-color: #eee;
        left: -60px;
        top: -40px;
        position: absolute;
        box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);

        @media (max-width: 767px) {
            left: -30px;
        }
    }

    .bottom-img {
        border-style: solid;
        border-width: 10px;
        border-color: rgb(255, 255, 255);
        box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
        background-color: #eee;
        position: absolute;
        width: 269px;
        height: 269px;
        right: -60px;
        bottom: -60px;

        @media (max-width: 767px) {
            display: none;
        }
    }
}

.block-contents {
    padding: 45px;

    @media (max-width: 1199px) {
        padding-right: 0;
    }

    @media (max-width: 991px) {
        margin-left: 0;
    }

    span {
        margin-bottom: 5px;
    }

	h4 { 
        margin-top: 40px;
        font-weight: 600;
        padding: 5px 28px;
        margin-bottom: 30px;
        border-left: 4px solid $theme-color;

        @media (max-width: 767px) {
            padding: 5px 20px;
            font-size: 18px;
        }
	}

	.theme-btn { 
        margin-top: 40px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        @media (max-width: 767px) {
            margin-top: 20px;
        }
    
	}

    ul {
        margin-top: 15px;
        @media (max-width: 767px) {
            margin-left: 0;
        }

        li {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
            padding-left: 30px;
            display: block;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "\f058";
                font-family: $fa;
                font-weight: 700;
                color: $theme-color;
           }
       }
    }
}

.photo-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;  

    .single-photo {
        background-color: #e6e6e6;
        overflow: hidden;
        width: 100%;
        border-radius: 10px;

        &:first-of-type {
            height: 570px;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        &:nth-of-type(2){
            height: 220px;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        &:nth-of-type(3){
            height: 320px;
        }

        a {
            display: block;
        }
    }
}

.user-data {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #e6e6e6;

    .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #e6e6e6;
        float: left;
        clear: both;
        overflow: auto;
        margin-right: 20px;
    }
    .user-info {
        margin-right: 40px;

        span {
            font-size: 14px;            
            display: block;
            line-height: 1;
        }
    }
    .phone-info {
        a {
            font-weight: 700;
        }
        span {
            font-size: 14px;            
            display: block;
            line-height: 1;
        }
    }
}

.video-fetaures-wrapper {
    .section-title {
        p {
            color: $theme-color-2;
        }
    }

    h3, h1, h2 { 
        color: $second-color;
    }
}

.features-list {
    .single-item { 
        overflow: hidden;
        margin-top: 40px;

        .icon { 
            background-color: #fff;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            float: left;
            overflow: hidden;
            margin-right: 40px;

            @media (max-width: 767px) {
                float: inherit;
                display: block;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    
        .content {
            overflow: hidden;

            @media (max-width: 767px) {
                display: block;
                padding-right: 50px;
            }
    
            p { 
                padding-left: 20px;
                margin-top: 15px;
                position: relative;

                &::before {
                    position: absolute;
                    width: 2px;
                    height: 42px;
                    left: 0;
                    top: 50%;
                    content: "";
                    transform: translateY(-50%);
                    background: $theme-color-2;
                }
            }
        }
    }
}

.core-features-list {
    .single-feature-item { 
        overflow: hidden;
        margin-top: 50px;
        
        &:hover {
            .icon {
                background: $theme-color-3;
            }
        }

        .icon { 
            float: left;
            width: 100px;
            height: 80px;
            background-color: #fff;
            box-shadow: $box-shadow;
            text-align: center;
            overflow: hidden;
            margin-right: 20px;
            line-height: 80px;
            -webkit-clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
            clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
            transition: all .3s ease-in-out;
        }
    
        .content { 
            overflow: hidden;

            h3 { 
                margin-top: -6px;
                font-size: 22px;
                margin-bottom: 5px;
            }
    
            p { 
    
            }
        }
    }
}

.video-featured-wrapper {
    .container-fuild {
        @media (min-width: 1200px) {
            max-width: 100%;
            width: 100%;
            padding: 0 120px;
        }
    }
}

.video-wrapper {
    .video-play-btn {
        a {
            -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            border-radius: 0;
            width: 120px;
            height: 100px;
            line-height: 100px;
            background-color: $theme-color-3;
            color: $second-color;

            &:hover {
                color: #fff;
            }
        }
    }
}

.video-featured-wrapper {
    position: relative;
    z-index: 0;
}

.video_bg {
    position: absolute;
    width: 50%;
    height: 100%;
    content: "";
    background-size: cover;
    right: 0;
    top: 0;
    z-index: -1;
}

.icon-boxs {
    padding-top: 20px;
    border-top: 1px solid #e5e5e5;

    .single-icon-box { 
        margin-top: 30px;
        overflow: hidden;
        padding-right: 50px;

        @media (min-width: 767px) {
            padding-right: 0;
        }

        .icon { 
            float: left;
            border-radius: 50%;
            background-color: $theme-color;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            color: rgb(65, 57, 57);
            font-size: 42px;
            margin-right: 25px;

            @media (max-width: 585px) {
                float: none;
                display: inline-block;
                margin-bottom: 20px;
            }
        }
    
        .content { 
            overflow: hidden;

            @media (max-width: 585px) {
                display: block;
            }

            p { 
                margin-top: 5px;
            }
        }
    }
}

.photo-style-grid {
    position: relative;

	.top-img { 
        width: 180px;
        height: 180px;
        background-color: #eee;
        margin-bottom: -120px;
        position: relative;
        z-index: 3;
	}

	.main-img { 
        background-color: #fff;
        margin: 0px 50px;
        height: 560px;
        position: relative;

        @media (max-width: 1199px) {
            height: 450px;
        }

        @media (max-width: 991px) {
            height: 400px;
        }

        @media (max-width: 585px) {
            height: 200px;
            margin-bottom: 30px;
        }

	}

	.bottom-img { 
        width: 280px;
        height: 280px;
        background-color: #fff;
        border: 10px solid #fff;
        position: relative;
        z-index: 3;
        float: right;
        margin-top: -190px;
        margin-right: -50px;

        @media (max-width: 1199px) {
            margin-right: 0;
        }

        @media (max-width: 585px) {
            display: none;
        }
	}
}

.eu{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 24px;
    width: 100%;
    background: white;
    border-top: 2px solid #001659;
}
.info_content .attachment__caption{
  display: none;
}
.info_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.info_content img{
    align-self: center;
}
img {
    float: left;
}
.logo_eu img {
    width: 555px;
    float:left
}
@media screen and (max-width: 900px) {
    .logo_kempes_eu {
    display: none;
}
.logo_eu img {
    width: auto;
}
}