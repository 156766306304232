/* ----------------------------------
   Hero Section - Styles
 ------------------------------------ */
.hero-1 {
    position: relative;

    .hero-contents {
        padding-top: 215px;
        padding-bottom: 220px;
        overflow: hidden;
        
        @media (min-width: 991px) {
            margin-left: 20px;
        }

        @media (max-width: 1200px) {
            padding-top: 180px;
            padding-bottom: 190px;
        }

        @media (max-width: 991px) {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media (max-width: 767px) {
            padding-top: 100px;
            padding-bottom: 110px;
        }

        h1 {
            margin-bottom: 30px;
            color: #fff;
            text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.7);

            @media (max-width: 767px) {
                margin-bottom: 25px;
            }
        }
    
        p { 
            margin-bottom: 20px;
            color: #EBEDF2;
            font-size: 20px;
            text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.7);
        }
    
        .theme-btn { 
            margin-top: 20px;
            overflow: hidden;
            margin-right: 20px;
        }

        .plus-text-btn {
            color: #fff;
            display: inline-block;
            overflow: hidden;
            clear: both;
            margin-top: 20px;

            &:hover {
                .icon {
                    background: $theme-color;
                    color: #fff;
                }
            }
            
            .icon {
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                color: $second-color;
                background: #fff;
                border-radius: 50px;
                margin-right: 20px;
                float: left;
                overflow: auto;
                transition: all .3s ease-in-out;
            }

            .link-text {
                float: left;
                overflow: auto;
            }

            span {
                display: block;
            }

        }
    }

    .hero-slider-active {
        position: relative;
    
        &.owl-theme {
    
            &:hover {
                .owl-nav {
                    div {
                        opacity: 1;
                        visibility: visible;
                        transform: scale(1);
                    }
                }
            }
    
            .owl-nav {
                margin: 0;      
    
                div {
                    left: 20px;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                    color: $white;
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    text-align: center;
                    font-size: 20px;
                    display: inline-block;
                    border-radius: 0px;
                    z-index: 99;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    transition: all .3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(.85);
                    
                    @media (max-width: 991px) {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 16px;
                    }
    
                    @media (max-width: 767px) {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 14px;
                    }
    
                    @media (max-width: 500px) {
                        display: none;
                    }
        
                    &:hover {
                        border-color: $theme-color;
                        background-color: $theme-color;
                        box-shadow: 0px 10px 35px 0px rgba(38, 42, 76, 0.1);
                        color: #fff;
                    }
        
                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                }
            }
        }
    
    }

    .owl-nav {   

        div {
            position: absolute;
            right: 120px;
            bottom: 0;
            padding: 18px 31px;
            line-height: 1;
            text-transform: uppercase;
            background-color: #fff;
            color: $theme-color;

            @media (max-width: 767px) {
                font-size: 14px;
                padding: 14px 28px;
                right: 108px;
            }

            &:hover {
                color: $second-color;
            }

            &.owl-prev {
                i {
                    margin-right: 5px;
                }
            }

            &.owl-next {
                right: 0px;
                i {
                    margin-left: 5px;
                }
            }
        }
    }

}

.hero-2 {
    .single-slide {
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 60%);
            content: "";
            z-index: -1;
        }
    }
    .hero-contents {
        padding: 200px 0px;

        @media (max-width: 1191px) {
            padding: 150px 0px;
        }

        @media (max-width: 991px) {
            padding: 110px 0px;
        }

        @media (max-width: 767px) {
            padding: 80px 0px;
            text-align: center;
        }

        h1 {
            font-size: 95px;
            margin-bottom: 30px;
            color: $theme-color;

            @media (max-width: 1191px) {
                font-size: 70px;
            }

            @media (max-width: 991px) {
                font-size: 60px;
            }

            @media (max-width: 767px) {
               font-size: 48px;
            }
        }
    
        p { 
            margin-bottom: 20px;
            color: $theme-color;
            font-size: 20px;

            @media (max-width: 767px) {
                margin-bottom: 10px;
                font-size: 18px;
            }

        }
    
        .theme-btn { 
            margin-top: 20px;
            overflow: hidden;

            @media (max-width: 767px) {
                margin-top: 10px;
            }

            &:last-of-type {
                background-color: $theme-color;

                &:hover {
                    background: $second-color;
                }
            }
        }
    }

    .owl-theme {

        &:hover {
            .owl-nav {
                div {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }

            }
        }

        .owl-nav {
            margin: 0;      

            div {
                left: 20px;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                color: $white;
                background-color: #435283;
                margin: 0;
                padding: 0;
                width: 70px;
                height: 70px;
                line-height: 70px;
                text-align: center;
                font-size: 20px;
                display: inline-block;
                z-index: 99;
                border-radius: 50%;
                border: 2px solid #435283;
                transition: all .3s ease-in-out;
                transform: scale(.85);
                opacity: 0;
                visibility: hidden;
                box-sizing: border-box;
                
                @media (max-width: 991px) {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 16px;
                }

                @media (max-width: 767px) {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                }

                @media (max-width: 500px) {
                    display: none;
                }
    
                &:hover {
                    border-color: $theme-color;
                    background-color: $theme-color;
                    box-shadow: 0px 10px 35px 0px rgba(38, 42, 76, 0.1);
                    color: #fff;
                }
    
                &.owl-next {
                    left: auto;
                    right: 20px;
                }
            }
        }
    }
}

.hero-3 {
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 170px;

    @media (max-width: 1499px) {
        overflow: hidden;
    }

    &::before {
        width: 943px;
        height: 943px;
        background: linear-gradient(#4e9cf6 0%, #fff 100%);
        opacity: 0.23;
        filter: blur(50px);
        position: absolute;
        content: "";
        z-index: -1;
        border-radius: 50%;
        top: 0;
        left: -100px;
    }

    &::after {
        width: 943px;
        height: 943px;
        background: linear-gradient(#4ef6e5 0%, #fff 100%);
        opacity: 0.15;
        filter: blur(50px);
        position: absolute;
        content: "";
        z-index: -1;
        border-radius: 50%;
        right: -50px;
        bottom: -200px;
    }

    .hero-funfact {
        .single-fun-fact {
            h2 {
                @media (max-width: 991px) {
                    font-size: 36px;
                }
            }

            h3 {
                @media (max-width: 991px) {
                    font-size: 20px;
                }
            }
        }
    }

    .hero-content {

        @media (max-width: 991px) {
            padding: 0px 30px;
        }

        @media (max-width: 500px) {
            padding: 0px;
        }

        h1 {
            font-size: 90px;
            line-height: 1;
            text-transform: capitalize;

            @media (max-width: 991px) {
               font-size: 60px;
            }
        }

        > h2 {
            font-size: 90px;
            line-height: 1;
            text-transform: capitalize;
            color: $theme-color-4;
            position: relative;
            display: inline-block;
            border: 4px solid $theme-color-4;
            padding: 0px 15px 20px;
            transform: rotate(-10deg);
            
            @media (max-width: 991px) {
                font-size: 60px;
            }

            span {
                position: relative;

                &::after, &::before {
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $theme-color-4;
                    top: 0;
                    top: -5px;
                    right: -25px;
                }

                &::after {
                    bottom: -5px;
                    top: auto;
                }
            }

            &::after, &::before {
                position: absolute;
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $theme-color-4;
                left: 0;
                top: 0;
                top: -10px;
                left: -10px;
            }

            &::after {
                bottom: -10px;
                top: auto;
            }
        }

        p {
            margin-top: 50px;
            font-size: 20px;
            padding-right: 200px;

            @media (max-width: 991px) {
                padding-right: 0;
                font-size: 18px;
            }
        }

        a {

        }
    }

    .play-icon {
        position: absolute;
        left: 50px;
        top: 20%;
        content: "";
        animation: movebounce 2s linear infinite forwards;
    }

    .hero-image { 
        position: relative;
        z-index: 1;

        .big-img { 
            position: relative;
            
            > img {
                border-radius: 17px;

                @media (max-width: 991px) {
                   max-width: 70%;
                   margin: 0px auto;
                   display: block;
                   margin-top: 30px;
                }

                @media (max-width: 500px) {
                   max-width: 85%;
                }
            }

            @media (min-width: 1800px) {
                margin-right: -300px;
            }

            @media (max-width: 1799px) {
                margin-right: -150px;
            }

            @media (max-width: 1499px) {
                margin-right: -100px;
            }

            @media (max-width: 1399px) {
                margin-right: 0px;
            }
    
            .element { 
                
                img { 
                    position: absolute;
                    left: 30%;
                    bottom: -50px;
                    z-index: -1;
                    animation: bounceUp 4s linear infinite forwards;

                    &:last-child {
                        left: auto;
                        right: 0;
                        top: 50px;
                        z-index: 2;
                        animation: movebounce 3s linear infinite forwards;
                        
                        @media (max-width: 1499px) {
                            right: -50px;
                        }

                        @media (max-width: 991px) {
                           right: 0;
                        }
                    }
                }
            }
        }
    
        .card-img {
            img {
                left: -100px;
                bottom: -100px;
                position: absolute;
                z-index: 2;
                border-radius: 15px;
                box-shadow: 0px 20px 60px rgba(23, 52, 109, 0.09);
                animation: moveleftbounce 5s linear infinite forwards;

                @media (max-width: 991px) {
                    left: 0;
                }
            }
        }
    }
}

.agency-slider-active {

    .single-agency-slide {
        padding: 285px 0px 305px;
        position: relative;
        background-position: top right;
        background-size: cover;

        @media (max-width: 991px) {
            background-position: top center;
         }

        @media (max-width: 1300px) {
            padding: 190px 0px 200px;
        }

        @media (max-width: 991px) {
            padding: 140px 0px 160px;
        }

        .hero-contents {
            @media (max-width: 767px) {
               text-align: center;
               padding: 0px 50px;
            }

            @media (max-width: 500px) {
                padding: 0px 20px;
            }

            h1 {
                font-size: 130px;
                line-height: 110%;
                font-weight: 700;
                text-transform: uppercase;

                @media (max-width: 991px) {
                   font-size: 100px;
                }

                @media (max-width: 767px) {
                   font-size: 80px;
                }

                @media (max-width: 500px) {
                   font-size: 60px;
                }
            }

            a {

            }
        }
    }


    &.owl-theme {
        .owl-dots {
            position: absolute;
            right: 20px;
            z-index: 9;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;

            .owl-dot {
                display: block;
                width: 100%;

                &.active, &:hover {
                    span  { 
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                span  {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    background: transparent;
                    margin: 2px 0px;
                    display: inline-block;

                    &::before {
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        content: "";
                        background: #fff;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s ease-in-out;
                    }
                }
            }
        }
    }
}

.owl-carousel {
    .animated-text {
      transform: translateX(40px);
      opacity: 0;
      visibility: hidden;
      transition: all .5s;
  
      &.is-transitioned {
        transform: none;
        opacity: 1;
        visibility: visible;
        transition: all 1.1s;
      }
    }
  
    .small-heading.is-transitioned {
      transition-delay: 0.2s;

      &:last-of-type {
        transition-delay: 0.5s;
      }
    }
  
    .big-heading.is-transitioned {
      transition-delay: 0.9s;

      &:last-of-type {
        transition-delay: 1.5s;
      }
    }
  
    .animated-btn.is-transitioned {
      transition-delay: 1s;

      &:last-of-type {
        transition-delay: 1.2s;
      }
    }
}

.rotate-social-link {
    position: absolute;

    @media (min-width: 1300px) {
        transform: rotate(-90deg) translateY(-50%);
        bottom: 50%;
        left: -95px;
    }

    z-index: 99;
    bottom: 80px;
    left: 30px;

    @media (max-width: 991px) {
        left: 15px;
        bottom: 50px;
    }

    a {
        color: #fff;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        margin: 0px 10px;

        &:hover {
            color: $theme-color-5;
        }
    }
}


.agency-wrapper{
    position: relative;
    .it-wrapper{
        padding: 110px 0 110px 0 ;
        position: relative;
        @media (max-width:768px) {
            padding: 70px 0 70px 0 ;
        }
        .banner-text{
            position: relative;
            @media (max-width:576px) {
               text-align: center;

               .yellow-bg{
                top: 38% !important;
                left: 2% !important;
                }
            }
            h6{
                color: $theme-color;
                font-weight: 400;
                font-family:$body-font;
                font-size: 15px;
            }
            .yellow-bg{
                position: absolute;
                top: 41%;
                left: -8%;
                z-index: -1;
            }
            h1{
                font-family:$heading-font;
                font-weight: 700;
                font-size: 55px;
                text-transform: capitalize;
                padding-bottom: 30px;
                span{
                   color: $second-color;
                }
                @media (max-width:600px) {
                    font-size: 35px;
                }
                @media (max-width:425px) {
                    font-size: 30px;
                }
            }
            p{
                font-weight: 400;
                font-family:$body-font;
                font-size: 20px;
                padding-left: 20px;
                margin-bottom: 60px;
                border-left: 3px solid $theme-color-4;
                @media (max-width:576px) {
                    border-left:none;
                    padding-left:0px;
                 }
            }
        }
        .expr-wepper-one{
            position: absolute;
            right: -20%;
            top: -20%;
            z-index: 5;
            overflow-y: hidden;
            @media (max-width:1300px) {
                position: absolute;
                left:-10%;
            }
            @media (max-width:1080px) {
                position: absolute;
                top: -5%;
                left: 0%;
                bottom: -191px;
            }
            @media (max-width:768px) {
                position: relative;
                left:0%;
                top: 50px;
            }
        }
        .expr-wepper-two{
            position: absolute;
            bottom: -130px;
            left: 0px;
            z-index: 5;
            animation:top-to-down 4.0s ease-in-out infinite;
            @media (max-width:1300px) {
                position: absolute;
                left:0%;
            }
            @media (max-width:768px) {
                position: relative;
                left:0%;
                top: 50px;
            }
        }
        .hero-rapper{
            position: absolute;
            top: 15%;
            right: 0%;
            z-index: 1;
            @media (max-width:768px) {
                top: 40%;
            }
        }
        .btn-wepper{
            .border{
                border: 2px solid red;
                display: inline-block;
                border-radius: 50%;
                margin-right: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 37px;
                width: 37px;
                i {
                    margin-right: 0px;
                    font-size: 10px;
                    padding: 10px 11px;
                    background-color: #4E5AFF;
                    color: rgb(255 255 255);
                    border-radius: 100%;
            }
            }
            @media (max-width:450px) {
                display: block !important;
                text-align: center;
                .ml-25{
                    margin-left: 0;
                }
                .mr-25{
                    margin-right: 0;
                }
               .btn-head-2 {
                margin-top: 50px;
               }
            } 
            @media (max-width:568px) {
               justify-content: center;
                .ml-25{
                    margin-left: 0;
                }
                .mr-25{
                    margin-right: 0;
                }
            } 
            @media (max-width:840px) {
                .ml-25{
                    margin-left: 5px;
                }
                .mr-25{
                    margin-right: 5px;
                }
            } 

        }
        .shapes{
            position: absolute;
            bottom: -45%;
            left: 0%;
            z-index: -1;
        }
    }

    .brand-wepper{
        position: relative;
        padding: 105px 0px;
        @media (max-width:768px) {
            padding: 0px 50px;
        }
        .brand-head{
            h5{
                font-size: 20px;
                text-transform: capitalize;
                color: $textcolor;
                font-family: $body-font;
                font-weight: 500;
                margin-bottom: 40px;
                @media (max-width:576px) {
                    font-size: 18px;
                 }
            }
        }
        .cloned{
            img{
                filter: saturate(0);
                @include transition(0.2s);
                &:hover{
                    filter: saturate(100);
                }
            }
           
        }
        .hero-rapper{
            position: absolute;
            top: 0%;
            left: 0%;
            z-index: 1;
        }
    }

    .info-wepper{
        padding-bottom: 110px;
        .section-title-2{
            position: relative;
            @media (max-width:768px) {
                margin-top: 100px;
            }
            h5{
               @include sectionhead-h5 ;
                padding-bottom: 15px;
            }
            h1{
                @include sectionhead-h1 ;
                margin-bottom:5px;
                padding-bottom: 10px;
            }
            .yellow-bg{
                z-index: -1;
                position: absolute;
                bottom: 5%;
                left: 13%;
            }
        }
        .service-wepper{
            overflow: hidden;
            .service-box{
                margin-top: 30px !important;
                border-radius: 10px;
                box-shadow: 0px 4px 24px 0px rgb(0 22 89 / 4%);
                position: relative;
                margin: 5px;
                background-color: $white;
                .icon{
                    transition: all 0.4s ease-in-out;
                     display: inline-block;
                     border-radius: 5px;
                     padding: 10px;
                     img{
                        height: 45px;
                        width: auto;
                     }
                 }
                 .content{
                     h5{
                         text-transform: capitalize;
                         font-family: $body-font;
                         padding: 20px 0px;
                         margin-bottom: 0px;
                     }
                     p{
                         font-family: $body-font;
                         font-size: 15px;
                     }
                 }
                 .borderb-1{
                     border: 2px solid #F2F3FE;
                 }
                 .borderb-2{
                     border: 2px solid #FFCFB9;
                 }
                 .borderb-3{
                     border: 2px solid #B5DBCB;
                 }
                 .borderb-4{
                     border: 2px solid #FFEBB3;
                 }
                 .overly-service-box{
                    transition: all 0.4s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: -5px;
                    top: -5px;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    margin: 5px;
                    background-color: #4e9cf6;
                    .icon{
                        transition: all 0.4s ease-in-out;
                         display: inline-block;
                         border-radius: 5px;
                         padding: 10px;
                         img{
                            height: 45px;
                            width: auto;
                         }
                     }
                     .content{
                        h5{
                            text-transform: capitalize;
                            font-family: $body-font;
                            padding: 20px 0px;
                            margin-bottom: 0px;
                            color: $white;
                        }
                        p{
                            font-family: $body-font;
                            font-size: 15px;
                            color: $white;
                        }
                    }
                     .borderb-1{
                         border: 2px solid #F2F3FE;
                     }
                     .borderb-2{
                         border: 2px solid #FFCFB9;
                     }
                     .borderb-3{
                         border: 2px solid #B5DBCB;
                     }
                     .borderb-4{
                         border: 2px solid #FFEBB3;
                     }
                }
               &:hover{
                filter:none;
                .overly-service-box{
                    visibility:visible ;
                    opacity: 1;
                }
               }
            }
            .service-one ,.service-two{
                position: absolute;
                top: 15%;
                left: 10%;
            }
            .service-two{
                left: 80%;
                top: 8%;
            }
           
        }
    }
    .shape-one{
        position: absolute;
        top: 10%;
        left: 12%;
        @media (max-width:1730px) {
            left: 1%;
        }
        @media (max-width:1200px) {
            display: none;
        }
    }
    .shape-two{
        position: absolute;
        display: inline-block;
        left: 88%;
        top: 72%;
        @media (max-width:1730px) {
            right: 1%;
        }
        @media (max-width:1200px) {
            display: none;
        }
    }
}
.hero-wrapper{
    position: relative;
    z-index: 1;
    overflow: hidden;
    &.hero-4{
        position: relative;
        z-index: 1;
        overflow: hidden;
        .single-slide {
            padding-top: 250px;
            padding-bottom: 170px;
     
             @media (max-width: 1191px) {
                 padding-top: 250px;
                 padding-bottom: 170px;
             }
     
             @media (max-width: 991px) {
                 padding-top: 170px;
                 padding-bottom: 100px;
             }
     
             @media (max-width: 767px) {
                 padding-top: 170px;
                 padding-bottom: 80px;
                 text-align: center;
             }
            position: relative;
            z-index: -1;
            overflow: hidden;
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #01011C 0%, rgba(1, 1, 28, 0) 100%);
                content: "";
                z-index: -1;
            }
            .number__element{
                z-index: -1;
                position: absolute;
                left: 0%;
                bottom: 0%;
                @media (max-width:768px) {
                   display: none;
                }
            }
        }
        .hero-contents{
            z-index: 100;
            @media (max-width:500px) {
                text-align: center !important;
            }
            h1{
                font-family: $text-font;
                font-style: normal;
                font-weight: 700;
                font-size: 60px;
                line-height: 150%;
                color: $white;
                margin-bottom: 20px;
                span{
                    font-size: 70px;
                }
                @media (max-width:995px) {
                    font-size: 45px;
                    max-width: 500px;
                    span{
                        font-size: 55px;
                    }
                }
                @media (max-width:500px) {
                    font-size: 36px;
                    span{
                        font-size: 40px;
                    }
                }
                @media (max-width:400px) {
                    font-size: 32px;
                    span{
                        font-size: 32px;
                    }
                }
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: $white;
                font-family: $body-font;
                margin-bottom: 40px;
            }
            a{
                border-radius: 4px;
                padding: 15px 30px;
                &:last-child{
                    @include btn-trns;
                    i{
                        margin-left: 0;
                        margin-right: 10px;
                    }
                    @media (max-width:400px) {
                        margin-top: 30px;
                    }
                }
            }
        }
        .element{
            z-index: 1;
            font-family: $text-font;
            font-style: normal;
            font-weight: 800;
            font-size: 90px;
            line-height: 135px;
            top: 35%;
            left: -3%;
            text-transform: uppercase;
            -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
            color: rgb(0 0 0 / 0%);
            position: absolute;
            transform: rotate(-90deg);
            @media (max-width:1800px) {
                left: -7%;
            }
            @media (max-width:1650px) {
                left: -10%;
            }
            @media (max-width:1550px) {
                left: -15%;
            }
            @media (max-width:1400px) {
                left: -17%;
            }
            @media (max-width:1200px) {
               display: none;
            }
        }
        .hero-slider_3{
            .owl-nav{
                @media (max-width:768px) {
                    display: none;
                }
                position: absolute;
                bottom: -100px;
                right: -100px;
                height: 300px;
                width: 300px;
                background-color: $title-color-2 !important;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .owl-prev,.owl-next{
                    margin-bottom: 20px;
                    margin-right: 20px;
                    color: rgb(255 255 255);
                    font-size: 14px;
                    margin: 5px;
                    padding: 0;
                    background: transparent;
                    display: inline-block;
                    cursor: pointer;
                    border-radius: 3px;
                    &:hover{
                        background: transparent;
                    }
                    p{
                        @include transition(0.4s);
                       
                    }
                    &:hover{
                        p{
                            color: rgb(255 94 20);
                        }
                    }
                }
            }
        }
    }
    .owl-nav {
        position: absolute;
        bottom: 0;
        right: 0%;
    }
}